header[role="main"] {
  background: #1c1c1c none;
  height: 84px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  @media print {
    position: relative;
    height: auto;
    padding: 40px 0 20px;
  }
  #logo {
    width: 280px;
    background: #000 url(../images/logo-mackey-mitchell.svg) no-repeat 50% 50%;
    background-size: 203px auto;
    display: block;
    float: left;
    height: 100%;
    img {
      display: none;
      @media print {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    @media print {
      float: none;
      margin: 0 auto 30px;
    }
  }
  .toggle {
    height: 0;
    display: none;
  }
  nav {
    float: right;
    text-align: right;
    width: 50%;
    height: 30px;
    margin-right: 40px;
    @extend %vertical;
    font-size: 0.92857142857143em;
    @extend .BreveSansSemiBold;
    @media print {
      display: none;
    }
    a {
      display: inline-block;
      padding: 4px 0 10px;
      text-transform: uppercase;
      color: $gray;
      margin: 1px 0 0 16px;
      letter-spacing: 0.05769230769231em;
      &.active, &:hover {
        color: #fff;
      }
      &.active {
        border-top: 3px solid #fff;
      }
    }
  }
  .home & {
    background: transparent none;
    
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAyMDIwMiIgc3RvcC1vcGFjaXR5PSIwLjYzIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjM0JSIgc3RvcC1jb2xvcj0iIzU5NTk1OSIgc3RvcC1vcGFjaXR5PSIwLjYzIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijk5JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a1020202', endColorstr='#00ffffff',GradientType=0 ); /* IE6-8 */
    
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.1) 60%, rgba(0,0,0,0) 80%);
    
    
    height: 126px;
    #logo {
      background: transparent url(../images/logo-mackey-mitchell-home.svg) no-repeat 50% 50%;
      width: 305px;
      height: 60px;
      @extend %vertical;
      left: 70px;
    }
    nav {
      a {
        color: #fff;
        &:hover {
          color: #000;
        }
      }
    }
  }
  @include bp($tablet) {
    .home & {
      nav {
        margin-right: 20px;
      }
      #logo {
        left: 30px;
      }
    }
  }
  @include bp($mobile) {
    background: #000;
    height: 155px;
    box-sizing: border-box;
    padding-bottom: 45px;
    overflow: visible;
    position: relative;
    .toggle {
      border: none;
      outline: none;
      background: #1c1c1c;
      color: #fff;
      display: block;
      position: absolute;
      text-transform: uppercase;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 100;
      height: 45px;
      width: 100%;
      cursor: pointer;
      span {
        position: relative;
        padding-left: 24px;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.05769230769231em;
        //@extend .BreveSansSemiBold;
        font-family: 'Breve Sans Text SemiBold',sans-serif;
        font-weight: 400;
        &:before {
          width: 14px;
          content: "";
          height: 8px;
          border-top: 2px solid #b3b2b2;
          border-bottom: 2px solid #b3b2b2;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -6px;
        }
        &:after {
          content: "";
          height: 2px;
          background: #b3b2b2;
          width: 14px;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -1px;
        }
      }
    }
    #logo {
      float: none;
      margin: 0 auto;
      display: block;
      width: 100%;
      left: 0;
    }
    nav {
      background: #1c1c1c;
      position: absolute;
      margin-top: 155px;
      width: 100%;
      float: none;
      margin: 0;
      top: 155px;
      text-align: center;
      height: auto;
      transform: translateY(0);
      padding-bottom: 10px;
      display: none;
      &.open {
        display: block;
      }
      a {
        display: block;
        padding: 5px 0;
        margin: 0;
        float: none;
        width: 100%;
        color: $gray;
        &:hover {
          color: #fff;
        }
        &.active {
          border: none;
          color: #fff;
        }
      }
    }
    .home & {
      background: #000;
      height: 155px;
      #logo {
        background: #000 url(../images/logo-mackey-mitchell.svg) no-repeat 50% 50%;
        left: 0;
      }
      nav a:hover {
        color: $gray;
      }
    }
  }
}