.page-template-page-our-firm-php {
  .group {
    .bg {
      text-align: left;
      .text {
        background-image: url(../images/text-collaboration.svg);
        padding-bottom: 11.5%;
      }
    }
  }
  .values {
    .js-slide-wrap {
      min-height: 110px;
      margin-top: 0;
      li:first-child {
        padding-left: 0;
      }
      @include bp(1270px) {
        font-size: 0.9em;
        li {
          border: none;
          padding-left: 0;
        }
      }
      @include bp($tablet) {
        font-size: 1em;
      }
      @include bp($mobile) {
        margin-top: 2em;
        min-height: 133px;
        li, 
        li:first-child {
          padding: 0 40px;
        }
      }
      @media print {
        min-height: 0;
        height: auto;
      }
    }
    .dots a {
      background: #000;
      opacity: 1;
      &.active {
        background-color: #fff;
      }
    }
    li:last-child {
      padding-right: 7%;
      box-sizing: border-box;
    }
  }
  .people {
    text-align: left;
    @media print {
      position: relative;
      width: 100%;
      padding: 0;
      box-sizing: border-box;
    }
    .member {
      float: none;
      display: inline-block;
      margin-left: 38px;
      margin-right: 38px;
      text-align: center;
      h4 {
        margin-top: -0.3em;
      }
      h2, 
      h4 {
        position: relative;
        z-index: 2;
      }
      &:after {
        content: "";
        width: 0px;
        border-left: 11px dotted transparent;
        border-right: 11px dotted transparent;
        border-top: 20px solid white;
        position: relative;
        z-index: 1;
        bottom: 3px;
        transition-duration: $timing;
      }
      @media print {
        display: none;
      }
      &.active {
        @media print {
          display: block;
          margin: 0;
          float: none;
          height: auto !important;
          width: 100%;
          padding: 0;
          max-width: 100%;
          > a img {
            max-width: 200px;
            height: auto;
          }
        }
        &:after {
          bottom: -18px;
        }
        .img {
          &:after {
            background: #000 none;
          }
          &:before {
            content: "";
            width: 14px;
            height: 1px;
            background: #a3a3a3;
            position: absolute;
            z-index: 10;
            bottom: 0;
            left: 50%;
            margin-left: -7px;
          }
        }
      }
    }
    .member .entry {
      h1.name {
        color: #000000;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: 0.02272727272727em;
        margin: 0 0 0.2em;
        @extend .BreveNewsSemibold;
      }
      h4.position {
        color: #ffffff;
        text-transform: uppercase;
        margin-top: 0;
        font-size: 15px;
        line-height: 20px;
        @extend .BreveSansBold;
        letter-spacing: 0.06666666666667em;
      }
      @media print {
        max-width: 100%;
        padding: 0;
        position: relative;
        left: 0;
        width: 90%;
      }
      aside,
      .mobile-info {
        float: left;
        width: 30%;
        height: auto;
        display: block;
        margin: 65px 0 40px;
        text-align: left;
        text-transform: uppercase;
        font-size: 0.92857142857143em;
        letter-spacing: 0.07692307692308em;
        @media print {
          display: none;
        }
        img {
          float: none;
          width: 100%;
          margin: 0 0 29px;
        }
        p {
          margin: 0.9em 0 0;
          @extend .BreveSansSemiBold;
          text-align: center;
        }
        .phone {
          display: inline-block;
          vertical-align: middle;
          padding: 10px 0;
        }
        a.email {
          color: #000;
          background: #fff;
          padding: 1px 29px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 15px 0 0;
          font-size: 0.92307692307692em;
          letter-spacing: 0.08333333333333em;
          &:hover {
            background: #000;
            color: #fff;
          }
        }
        ul {
          min-width: 140px;
          list-style: none;
          margin: 27px 0 0;
          padding: 0;
          display: block;
          text-align: center;
          width: 100%;
          font-size: 1.25em;
          li {
            text-align: left;
            display: inline-block;
            vertical-align: bottom;
            margin: 0 10px;
            a {
              text-align: left;
              margin: 0;
              vertical-align: bottom;
              &:before {
                margin: 0;
              }
            }
          }
        }
      }
      .mobile-info {
        display: none;
      }
      .view {
        text-transform: uppercase;
        font-size: 0.85714285714286em;
        @extend .BreveSansMedium;
        &:hover {
          color: #000;
        }
      }
      .social a:hover {
        color: #000;
      }
      p {
        line-height: 1.78571428571429em;
      }
      .info {
        display: none;
      }
    }
  }
  .testimonial {
    h2 {
      font-size: 2.42857142857143em;
      line-height: 1.14705882352941em;
    }
    p,
    h2 {
      padding-right: 22%;
      padding-left: 18%;
    }
    p {
      margin-top: 20px;
    }
    @include bp($tablet) {
      h2 {
        font-size: 1.92857142857143em;
      }
      p, 
      h2 {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .single {
    width: 50%;
    position: absolute;
    left: 50%;
    z-index: 0;
    top: 0;
    z-index: 0;
    height: 100%;
    color: #fff;
    height: 0;
    padding-bottom: 34.25%;
    background: url('/_fpo/image-history.jpg') no-repeat 50% 0;
    background-size: 100% auto;
    @media print {
      display: none;
    }
    .blend {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(116,205,217,0.5);
    }
    .center {
      @extend %vertical;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 10;
    }
    h2 {
      font-size: 4.22857142857143em;
      line-height: 1.3em;
      @extend .BreveBlack;
      letter-spacing: 0.05em;
    }
    h5 {
      font-size: 0.92857142857143em;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 1.4em;
      letter-spacing: 0.13em;
      @extend .BreveSansMedium;
    }
    &:before {
      content: "";
      background: url(../images/logo-mackey-mitchell.svg) no-repeat 12.85% 25%;
      background-size: 1000% auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 8;
      opacity: 0.6;
    }
    &:after {
      content: "";
      width: 60px;
      height: 60px;
      background: #000 url(../images/sprite.png) no-repeat 0 0;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -30px;
      z-index: 20;
      transition-property: background-color;
      transition-duration: $timing;
    }
    &:hover {
      &:after {
        background-color: #fff;
        background-position: -120px 0;
      }
    }
    &.active {
      &:after {
        background-position: -120px -60px;
      }
      &:hover {
        &:after {
          background-color: #fff;
          background-position: -120px -120px;
        }
      }
    }
    &[data-split="our-people"] {
      background: none no-repeat 50% 0;
      background-size: 100% auto;
      left: 0;
      .blend {
        background-color: rgba(227,53,59,0.4);
      }
      &:before {
        background-position: 1.25% 25%;
      }
    }
    @include bp($mobile) {
      &:after {
        transform: scale(0.6,0.6);
        bottom: 10px;
      }
    }
  }
  .split {
    position: relative;
    z-index: 20;
    padding-top: 34%;
    transition-duration: $timing;
    transition-property: padding;
    @include bp($tablet) {
      padding-top: 45%;
    }
    @include bp($mobile) {
      padding-top: 0;
    }
    &.open {
      padding-top: 0;
    }
  }
  /* People */
  .top {
    background: #000;
    overflow: visible;
    position: relative;
    z-index: 1000;
    article {
      width: 100%;
      box-sizing: border-box;
      padding: 6% 56% 6% 6%;
      position: relative;
      color: #8a8a8a;
      @extend .BreveSansLight;
      @media print {
        padding: 30px 0;
      }
      h1 {
        color: #fff;
        font-size: 3.92857142857143em;
        text-align: center;
        line-height: 1em;
        margin-bottom: 0.5em;
        letter-spacing: 0.00675675675676em;
        font-weight: 400;
        @extend .BreveBlack;
        @media print {
          font-size: 2.5em;
        }
      }
      .entry {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        @include bp($mobile) {
          max-width: 100%;
        }
        @media print {
          max-width: 100%;
        }
      }
    }
  }
  .filters {
    padding: 4% 0 0;
    margin: 0 auto;
    width: 295px;
    @include bp($tablet) {
      width: 100%;
    }
    @media print {
      display: none;
    }
    h4 {
      column-span: all;
      text-align: center;
      display: block;
      color: $red;
      text-transform: uppercase;
      font-size: 1.07142857142857em;
      letter-spacing: 0.14em;
      margin: 0.8em 0;
      line-height: 1.4em;
      @extend .BreveSansSemiBold;
    }
    ul {
      list-style: none;
      width: 100%;
      margin: 0 0 0 7px;
      padding: 15px 0 0;
      overflow: hidden;
      li {
        width: 50%;
        float: left;
        min-height: 28px;
        margin: 0 0 5px 0;
        padding: 0;
        &:nth-of-type(even) {
          width: 40%;
          a {
            padding-right: 0;
          }
        }
        &:nth-of-type(odd) {
          width: 60%;
        }
      }
      a {
        text-transform: uppercase;
        color: $gray;
        font-size: 0.85714285714286em;
        line-height: 1.2em;
        letter-spacing: 0.0625em;
        display: block;
        padding-right: 10px;
        @extend .BreveSansMedium;
        &:hover, 
        &.active {
          color: #fff;
        }
      }
    }
  }
  .splitee {
    clear: both;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .people {
    overflow: hidden;
    position: static;
    padding: 6% 0 ;
    @include bp($mobile) {
      text-align: left;
    }
  }
  
  
  #our-history {
    .top {
      .bg {
        background: transparent none no-repeat 50% 50%;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        @media print {
          display: none;
        }
      }
      article {
        padding: 4% 6% 6% 56%;
        @include bp($tablet) {
          padding: 8% 4% 6% 54%;
        }
        @media print {
          padding: 30px 0;
        }
        h1 {
          margin-bottom: 0.6em;
        }
        p {
          margin-bottom: 1.3em;
        }
        h4 {
          margin-top: 2em;
          font-size: 1.07142857142857em;
          letter-spacing: 0.06666666666667em;
        }
        ul.leaders {
          list-style: none;
          margin: 0;
          li {
            margin: .3em 0;
            font-size: 1.28571428571429em;
            @extend .BreveNewsMedium;
            a {
              color: #fff;
            }
            span {
              font-size: 0.66666666666667em;
              @extend .BreveSansMedium;
              letter-spacing: 0.0625em;
              text-transform: uppercase;
              color: #8a8a8a;
            }
          }
        }
      }
    }
  }
  .since {
    text-align: center;
    padding: 6% 4%;
    @media print {
      display: none;
    }
    h1 {
      font-size: 7em;
      line-height: 1em;
      margin-bottom: 19px;
    }
    p {
      text-transform: uppercase;
      @extend .BreveSansSemiBold;
      letter-spacing: 0.097em;
      line-height: 1em;
    }
  }
  .close {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: #434343 url(../images/sprite.png) no-repeat 0 0;
    outline: none;
    border: none;
    text-indent: -2000px;
    text-align: left;
    cursor: pointer;
    @media print {
      display: none;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: #434343;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition-duration: $timing;
    }
    &:after {
      content: "";
      width: 20px;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -10px;
      transition-duration: $timing;
      z-index: 10;
    }
    &:hover {
      background-color: #ccc;
      &:before {
        background-color: #ccc;
      }
    }
    @include bp($mobile) {
      transform: scale(0.6,0.6);
      top: -70px;
      left: 50%;
      right: auto;
      margin-left: -30px;
    }
  }
  #timeline {
    overflow: hidden;
    width: 100%;
    position: relative;
    @media print {
      display: none;
    }
    .year {
      background: $red;
      color: #fff;
      text-align: center;
      height: 68px;
      width: 68px;
      line-height: 68px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 20px;
      left: -100px;
      margin-left: -34px;
      z-index: 10;
      margin-top: 18px;
      @include bp(1024px) {
        top: 80px;
      }
    }
    .decades {
      overflow: hidden;
      span, a {
        width: calc(100% / 6);
        display: block;
        float: left;
        background: #e6e6e6;
        color: #fff;
        text-align: center;
        padding: 19px 0;
        font-size: 1.19857142857143em;
        &:hover, &.active {
          background: #cacaca;
          color: #000;
        }
      }
      a {
        @extend .BreveSansBlack;
        letter-spacing: 0.03125em;
        font-weight: 400;
      }
      span, span:hover {
        background: $red;
        color: #fff;
        font-size: 1.42857142857143em;
        @extend .BreveNewsBold;
      }
      &.num1 {
        span, 
        a {
          width: calc(100% / 2);
        }
      }
      &.num2 {
        span, 
        a {
          width: calc(100% / 3);
        }
      }
      &.num3 {
        span, 
        a {
          width: calc(100% / 4);
        }
      }
      &.num4 {
        span, 
        a {
          width: calc(100% / 5);
        }
      }
      &.num5 {
        span, 
        a {
          width: calc(100% / 6);
        }
      }
      &.num6 {
        span, 
        a {
          width: calc(100% / 7);
        }
      }
      &.num7 {
        span, 
        a {
          width: calc(100% / 8);
        }
      }
      &.num8 {
        span, 
        a {
          width: calc(100% / 9);
        }
      }
      &.num9 {
        span, 
        a {
          width: calc(100% / 10);
        }
      }
      &.num10 {
        span, 
        a {
          width: calc(100% / 11);
        }
      }
      @include bp(1024px) {
        span {
          width: 100%;
          float: none;
        }
        &.num1 {
          span, 
          a {
            width: calc(100%);
          }
        }
        &.num2 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 2);
          }
        }
        &.num3 {
          span {
            
          }
          a {
            width: calc(100% / 3);
          }
        }
        &.num4 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 4);
          }
        }
        &.num5 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 5);
          }
        }
        &.num6 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 6);
          }
        }
        &.num7 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 7);
          }
        }
        &.num8 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 8);
          }
        }
        &.num9 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 9);
          }
        }
        &.num10 {
          span {
            width: 100%;
          }
          a {
            width: calc(100% / 10);
          }
        }
      }
    }
    .slider {
      height: 380px;
      margin: 0;
      background: #000;
      overflow: hidden;
      .slide-track {
        height: 380px;
      }
      .slick-slide {
        opacity: 0.2;
        transition-duration: $timing;
        transition-property: opacity;
        &.slick-center {
          opacity: 1;
        }
      }
      figure {
        height: 100%;
        position: relative;
        width: 100%;
        figcaption {
          position: absolute;
          bottom: 27px;
          left: 0;
          right: 0;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
          font-size: 0.85714285714286em;
        }
      }
      img {
        height: 380px;
        width: auto;
        display: block;
      }
      .img-with-wysiwyg {
        max-width: 900px;
        overflow: hidden;
        background: $blue;
        height: 380px;
        position: relative;
        @include bp($tablet) {
          width: 740px;
        }
        figure {
          width: auto;
          float: none;
          display: table-cell;
          height: 380px;
          position: relative;
          z-index: 1;
          //padding-right: 5%;
          padding-right: 0;
          img {
            display: block;
            min-height: 100%;
            min-width: 100%;
            max-width: 2000px !important;
          }
        }
        article {
          box-sizing: border-box;
          padding: 0;
          color: #fff;
          position: relative;
          top: 0;
          right: 0;
          bottom: 0;
          height: 380px;
          left: auto;
          z-index: 0;
          overflow: hidden;
          display: table-cell;
          vertical-align: middle;
          max-width: 500px;
          h1, 
          h2, 
          h3, 
          h4, 
          h5 {
            color: #000;
            font-size: 1.21428571428571em;
            letter-spacing: 0.02941176470588em;
            margin: 0 0 0.4em;
            line-height: 1.2em;
            @extend .BreveBlack;
            font-weight: 400;
          }
          .entry {
            //@extend %vertical;
            padding-right: 10%;
            padding-left: 35px;
          }
        }
      }
    }
  }
  @include bp($tablet) {
    .values {
      height: 180px;
      position: relative;
      h2 {
        height: 180px;
        width: 40%;
        span {
          padding: 0 20px;
        }
      }
      ul {
        width: 60%;
        min-height: 133px;
        padding-left: 0;
        li.js-slide, 
        li.js-slide:first-child {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
      .dots {
        width: 60%;
        position: absolute;
        right: 0;
        text-align: left;
        padding-left: 40px;
        box-sizing: border-box;
        bottom: 10px;
        a {
          margin: 0 9px 0 0;
          width: 9px;
          height: 9px;
        }
      }
    }
    .split .single {
      padding-bottom: 45%;
      background-position: 50% 50%;
      background-size: cover;
      &:first-child {
        background-position: 50% 50%;
        background-size: cover;
      }
      h2 {
        font-size: 3.78571428571429em;
      }
    }
  }
  @include bp($mobile) {
    .split {
      .single {
        display: block;
        width: 100%;
        float: none;
        height: 0px;
        padding-bottom: 70%;
        position: relative;
        left: 0;
        right: 0;
        h2 {
          font-size: 3em;
        }
      }
      .top article {
        padding: 40px 20px;
        @include bp($mobile) {
          padding-bottom: 0;
        }
        .filters ul {
          margin: 0;
          li {
            width: 50%;
            &:nth-of-type(even),
            &:nth-of-type(odd) {
              width: 50%;
              padding-right: 0;
            }
          }
        }
        .filters .dd {
          width: calc(100% + 40px);
          margin: 0 -20px;
        }
        > h1 {
          display: none;
        }
        .testimonial {
          display: none;
        }
      }
    }
    #our-history {
      .top article {
        width: 100%;
        float: none;
        padding: 30px 20px;
      }
      .bg {
        display: none;
      }
      .since, 
      #timeline {
        display: none;
      }
    }
    .people {
      padding: 0;
      font-size: 0;
      .member {
        width: 50%;
        margin: 0;
        display: inline-block;
        font-size: 14px;
        &:after {
          display: none;
        }
        .img {
          margin: 0;
          display: block;
          &:after {
            display: none;
          }
        }
        &.active {
          .img {
            background: #000;
            position: relative;
            img {
              opacity: 0.3;
            }
            &:before {
              content: "";
              height: 2px;
              position: absolute;
              width: 56px;
              background: #fff;
              z-index: 100;
              display: block;
              top: 50%;
              left: 50%;
              margin-left: -28px;
            }
          }
        }
        .entry {
          padding: 0;
          .name,
          .position {
            display: none;
          }
          .mobile-info {
            display: block;
            padding-bottom: 25px;
          }
          aside,
          .mobile-info {
            width: 100%;
            margin: 0;
            float: none;
            text-align: center;
            display: block;
            font-size: 0.92857142857143em;
            box-sizing: border-box;
            ul.social {
              width: auto;
              font-size: 1em;
              margin-top: 19px;
              li a {
                margin: 0;
                color: #ffffff;
                opacity: 1;
              }
            }
          }
          aside {
            p,
            ul.social {
              display: none;
            }
            img {
              margin-bottom: 15px;
            }
          }
          .bio {
            float: none;
            width: 100%;
            padding: 30px 20px;
          }
          .view {
            color: $red;
            text-align: center;
            display: block;
          }
          .info {
            display: block;
            text-align: center;
            h2, 
            h4 {
              display: block;
            }
            h2 {
              font-size: 22px;
              line-height: 25px;
              margin-bottom: 5px;
              letter-spacing: 0.02272727272727em;
            }
            h4 {
              color: #ffffff;
              text-transform: uppercase;
              margin-top: 0;
              font-size: 15px;
              line-height: 20px;
              //@extend .BreveSansBold;
              font-family: 'Breve Sans Text Bold';
              font-weight: 400;
              letter-spacing: 0.06666666666667em;
            }
          }
          .social {
            text-align: center;
            a {
              color: #000;
              opacity: 0.35;
              margin: 0 8px;
              font-size: 1.9em;
              text-align: center;
              &:hover {
                opacity: 1;
              }
            }
          }
          .contact a:nth-of-type(1) {
            background: #000;
            color: #fff;
            padding-left: 8px;
            padding-right: 8px;
            margin: 0 6px;
            &:hover {
              background: $red;
            }
          }
          button {
            display: none;
          }
        }
        h2,
        h4 {
          display: none;
        }
      }
    }
    .values {
      height: auto;
      h2 {
        height: auto;
        width: 100%;
      }
      .dots {
        width: 100%;
        text-align: center;
        padding: 0;
        position: relative;
        a {
          width: 12px;
          height: 12px;
          margin: 0 7px;
        }
      }
      ul {
        background: #e6e6e6;
        border: none;
        position: relative;
        width: 100%;
        li {
          border: none;
          margin: 0;
          text-align: center;
          padding: 40px 20px;
          width: 100%;
          float: none;
          box-sizing: border-box;
        }
      }
    }
  }
}