.wysiwyg {
  h1, 
  h2 {
    font-size: 5.28571428571429em;
    line-height: 0.87837837837838em;
    margin-bottom: 0.3em;
    letter-spacing: 0.00675675675676em;
    color: $red;
    font-weight: 400;
    @extend .BreveBlack;
    @include bp($mobile) {
      font-size: 3.28571428571429em;
    }
    @media print {
      color: #000;
      font-size: 2.5em;
    }
  }
  h2 {
    font-size: 3em;
  }
  h3, 
  h4, 
  h5 {
    color: #000;  
    text-transform: uppercase;
    font-size: 1.2em;
    letter-spacing: 0.14em;
    margin: 0.8em 0;
    line-height: 1.4em;
    @extend .BreveSansSemiBold;
  }
  h4 {
    font-size: 1.07142857142857em;
    color: $red;
  }
  h5 {
    font-size: 1em;
    margin-bottom: 0;
  }
  p {
    margin-bottom: .9em;
  }
  .alignnone {
    display: block;
    float: none;
    margin: 1.9em 0 0.9em;
  }
  .alignleft {
    float: left;
    margin: 7px 20px 0.9em 0;
  }
  .alignright {
    float: right;
    margin: 7px 0 0.9em 20px;
  }
  .aligncenter {
    display: block;
    margin: 7px auto 0.9em;
  }
  > .alignnone {
    margin-top: 0;
  }
  .wp-caption {
    max-width: 100%;
  }
  .wp-caption-text {
    color: #858585;
    font-size: 0.82142857142857em;
    text-transform: uppercase;
    padding: 4px 30px 4px 0;
    @extend .BreveSansSemiBoldItalic;
    font-style: italic;
    line-height: 1.4em;
    letter-spacing: 0.04347826086957em;
    margin: 0;
  }
  ul, 
  ol {
    margin: 0 0 0.9em 40px;
  }
  blockquote {
    background: $red;
    padding: 9% 10% 4.5%;
    color: #fff;
    text-transform: uppercase;
    text-align: right;
    @extend .BreveSansBold;
    box-sizing: border-box;
    margin: 1.9em 0 2.9em;
    h2 {
      margin: 0.4em 0;
      text-align: left;
      color: #000;
      text-transform: none;
      font-size: 1.78571428571429em;
      @extend .BreveBlack;
      line-height: 1.4em;
      letter-spacing: 0.01666666666667em;
      &:before {
        content: "\201C";
      }
      &:after {
        content: "\201D";
      }
    }
  }
  @include bp($mobile) {
    .alignnone,
    blockquote {
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
      max-width: calc(100% + 40px);
      .wp-caption-text {
        padding-left: 20px;
      }
    }
    blockquote h2 {
      font-size: 1.28571428571429em;
      line-height: 1.66666666666667em;
    }
  }
  &.bio_wysiwyg {
    p {
      margin: 1.7em 0;
    }
  }
}
