@font-face {
    font-family: 'kelsobold';
    src: url('../fonts/kelso_bold-webfont.woff2') format('woff2'),
         url('../fonts/kelso_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

//mixins
@mixin flexbox() {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

@mixin flex-direction($direction) {
  flex-direction: $direction;
  -webkit-flex-direction: $direction;
}

@mixin justify-content($justify) {
  justify-content: $justify;
  -webkit-justify-content: $justify;
}

@mixin align-items($align) {
  align-items: $align;
  -webkit-align-items: $align;
}

.page-template-page-50-years {
  .page-wrap {
    margin-bottom: 0;
    padding: 0;
    width: 100%;
  }

  .page-wrap:after {
    display: none;
  }

  .logo-icon {
    display: none;
  }

  @include bp($mobile, true) {
    .page-wrap {
      height: 100%;
    }

    header[role=main] {
      top: auto;
      bottom: 0;
      height: 68px;
      background: none;
      background: #000;

      #logo {
        background: transparent url(../images/logo-mackey-mitchell-no-icon.svg) no-repeat;
        background-size: 176px auto;
        background-position: 0 50%;
        width: 32%;
      }

      nav a:hover {
        color: rgba($white, .5);
      }
    }

    .logo-icon {
      display: block;
      position: absolute;
      z-index: 10;
      right: 66px;
      bottom: 50px;
      width: 80px;
      height: 80px;
    }
  }

  @include bp($tablet, true) {
    header[role=main] #logo {
      width: 260px;
    }
  }
}

.anni-intro {
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  left: 20px;
  right: 20px;
  top: 20px;
  height: calc(100vh - 195px);
  background: $green;
  color: #000;
  padding-top: 50px;
  transform: translateY(-110%);
  transition: .5s ease;

  &.show {
    transform: translateY(0);
    z-index: 1001;

    .inner {
      opacity: 1;
      visibility: visible;
    }
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }

  .inner {
    margin: 0 auto;
    width: 85%;
    max-width: 420px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    transition-delay: .6s;
  }

  .title {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.075em;
    margin-bottom: 1.5em;
  }

  .inner img {
    display: block;
    margin-bottom: 1em;
  }

  @media (max-height: 800px) {
    .inner img {
      max-height: 300px;
      width: auto;
    }
  }

  .wysiwyg {
    font-family: 'kelsobold', 'Breve Sans Text Book', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.8;
    letter-spacing: 0.03em;

    em {
      font-style: normal;
      color: $white;
    }
  }

  .button {
    display: inline-block;
    background: $white;
    color: #000;
    text-transform: uppercase;
    padding: 12px 24px 10px;
    line-height: 1;
    font-size: 14px;
    letter-spacing: 0.035em;

    &:hover {
      color: $white;
      background: #000;
    }
  }

  .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background: #000;

      &:before, &:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 1px;
        background: $white;
        top: calc(50% - 1px);
        left: calc(50% - 10px);
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:hover {
        background: $red;
      }
    }

  @include bp($mobile, true) {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    z-index: 1001;
    width: auto;
    left: 68px;
    right: 68px;
    top: 68px;
    bottom: 136px;
    padding-top: 0;
    height: auto;

    .title {
      font-size: 15px;
    }

    .wysiwyg {
      font-size: 32px;
    }

    &.hide {
      z-index: 1001;
      transform: translateY(calc(100% + 136px));
      visibility: visible;
      opacity: 1;
    }
  }

  @include bp($tablet, true) {
    width: 51%;
    bottom: 0;
    top: 0;
    left: auto;
    right: 0;

    &.hide {
      transform: translateY(100%);
    }
  }

  @include bp($desktop, true) {
    .inner {
      max-width: 480px;
    }

    .wysiwyg {
      font-size: 34px;
    }
  }
}

.anni-grid .more {
  position: absolute;
  z-index: 3;
  bottom: 40px;
  left: 0;
  right: 0;

  p {
    text-transform: uppercase;
    letter-spacing: 0.015em;
    margin: 0 auto .5em;
    width: 85%;
    text-align: center;
  }

  .icon {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: #000;
    border-radius: 50%;
  }

  svg {
    width: 16px;
  }

  @include bp($mobile, true) {
    display: none;
  }
}

.anni-grid {
  position: relative;
  width: 100%;

  @include bp($mobile, true) {
    height: 100%;
  }

  @include bp($tablet, true) {
    &:after {
      width: 28%;
    }
  }

  .container {
    list-style: none;
    position: relative;
    width: 100vw;
    height: calc(100% - 68px);
    @include flexbox();

    @include bp($mobile) {
      height: calc(100vh - 155px);
    }
  }

  .container.below {
    background: $green;
  }

  .container.below:after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 68px);
    background: linear-gradient(60deg, #ed1c24 0%,#74cdd9 58%,#74cdd9 100%);
    opacity: .75;

    @include bp($mobile) {
      height: 100%;
    }
  }

  @supports (mix-blend-mode: lighten) {
    .container.below:after {
      mix-blend-mode: lighten;
      opacity: 1;
    }
  }

  .container.above {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;

    @include bp($mobile) {
      position: static;
      @include flexbox();
      height: auto;

      img {
        opacity: 0;
        visibility: hidden;
        display: block;
      }
    }
  }

  .container.above li {
    opacity: 0;
    transition: .3s;

    @include bp($mobile) {
      opacity: 1;

      .inner,
      .icon-video {
        opacity: 1;
        visibility: visible;
      }

      &.video .inner {
        background: none;
      }
    }
  }

  .container.above li.image-with-caption,
  .container.above li.link {
    .inner {
      visibility: hidden;
      opacity: 0;
    }

    @include bp($mobile) {
      .inner {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  /*.container.above li:hover,*/
  .container.above li.hover {
    opacity: 1;
    visibility: visible;

    .inner {
      visibility: visible;
      opacity: 1;
    }

    &.video .inner {
      background: none;
    }
    .video-bg {
      visibility: visible;
    }

    .icon-video {
      visibility: visible;
      opacity: 1;
    }
  }

  .container.above li.video:hover {
    
  }

  /*.container.above li:hover {
    .icon-video {
      visibility: visible;
      opacity: 1;
    }
  }*/

  .container.below li {
    position: absolute;
  }

  li {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #444;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .inner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .inner {
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(#000, .7);
      color: #fff;
      padding: 20px;
    }

    img {
      width: 100%;
      opacity: 0;
      visibility: hidden;
      box-sizing: border-box;
    }

    .cat {
      color: $red;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      margin-bottom: 2em;
    }

    .entry-title {
      font-size: 24px;
      font-family: 'Breve News Bold';
      font-weight: normal;
      margin-bottom: .35em;
      line-height: 1.15;
    }

    .caption {
      box-sizing: border-box;
      font-size: 13px;
      letter-spacing: 0.025em;
      line-height: 1.45;
    }

    .year {
      font-family: 'Breve News Bold';
      font-size: 36px;
    }

    &.image-with-caption .inner {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      background: rgba(#000, .4);
    }

    .action {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40px;
      height: 40px;
      background: $red;
      transition: .2s;

      &:before, &:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 1px;
        background: $white;
        top: calc(50% - 1px);
        left: calc(50% - 10px);
      }

      &:after {transform: rotate(90deg);}
      &:hover {
        background: #000;
      }
    }

    @include bp($tablet) {
      .cat {
        display: none;
      }
      .caption {
        display: none;
      }
    }

    @include bp($mobile) {
      &.link .caption {
        display: block;
      }
      &.people .caption {
        display: none;
      }
    }

    @media (max-height: 800px) {
      .caption {
        display: none;
      }
    }

    @media (max-height: 800px) and (min-width: 768px ) {
      .cat {
        margin-bottom: .5em;
      }
    }

    @include bp($mobile) {
      padding: 0;
      width: 100%;

      .cat {
        display: block;
      }

      .inner {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  @include bp($mobile) {
    .container.below {
      li:nth-child(1) {
        left: 0;
        bottom: 0;
        width: 48%;
        height: 28%;
      }
      li:nth-child(2) {
        display: none;
      }
      li:nth-child(3) {
        display: none;
      }
      li:nth-child(4) {
        right: 0;
        top: 19%;
        width: 26%;
        height: 19%;
      }
      li:nth-child(5) {
        left: 0;
        top: 0;
        width: 48%;
        height: 38%;
      }
      li:nth-child(6) {
        left: 0;
        top: 38%;
        width: 32%;
        height: 34%;
      }
      li:nth-child(7) {
        right: 0;
        bottom: 0;
        width: 52%;
        height: 28%;
      }
      li:nth-child(8) {
        left: 48%;
        top: 0;
        width: 52%;
        height: 19%;
      }
      li:nth-child(9) {
        left: 48%;
        top: 19%;
        width: 26%;
        height: 19%;
      }
      li:nth-child(10) {
        display: none;
      }
      li:nth-child(11) {
        right: 0;
        top: 38%;
        width: 68%;
        height: 34%;
      }
      li:nth-child(12) {
        display: none;
      }
    }

    .container.above li.video {
      background-image: none !important;

      .img-desktop {
        display: none;
      }

      .img-mobile {
        opacity: 1;
        visibility: visible;
      }
    }

    .container.above li.people {
      a {
        background: none;

        .cat, .entry-title {
          position: relative;
          z-index: 1;
        }

        .entry-title {
          color: #000;
        }

        .action {
          z-index: 1;
        }
      }

      a:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(60deg, rgba(#ed1c24, .75) 0%,rgba(#74cdd9, .75) 58%,rgba(#74cdd9, .75) 100%);
      }

      @supports (mix-blend-mode: lighten) {
        a:after {
          mix-blend-mode: lighten;
        }
      }
    }
  }

  @include bp($mobile, true) {

    li {
      position: absolute;
    }

      li:nth-child(1) {
        left: 0;
        top: 40%;
        width: 43%;
        height: 30%;
      }
      li:nth-child(2) {
        left: 0;
        top: 0;
        width: 32%;
        height: 17%;
      }
      li:nth-child(3) {
        left: 0;
        top: 17%;
        width: 32%;
        height: 23%;
      }
      li:nth-child(4) {
        left: 54%;
        bottom: 0;
        width: 22%;
        height: 15%;
      }
      li:nth-child(5) {
        left: 32%;
        top: 0;
        width: 33%;
        height: 40%;
      }
      li:nth-child(6) {
        right: 0;
        bottom: 0;
        width: 24%;
        height: 30%;
      }
      li:nth-child(7) {
        left: 0;
        bottom: 0;
        width: 32%;
        height: 30%;
      }
      li:nth-child(8) {
        left: 32%;
        bottom: 15%;
        width: 44%;
        height: 15%;
      }
      li:nth-child(9) {
        left: 32%;
        bottom: 0;
        width: 22%;
        height: 15%;
      }
      li:nth-child(10) {
        display: none;
      }
      li:nth-child(11) {
        left: 43%;
        top: 40%;
        width: 57%;
        height: 30%;
      }
      li:nth-child(12) {
        right: 0;
        top: 0;
        width: 35%;
        height: calc(40% - 110px);
      }

    li.video {
      .img-mobile {
        display: none;
      }
    }
  }

  @include bp($tablet, true) {
    li:nth-child(1) {
      left: 0;
      top: auto;
      bottom: 0;
      width: 28%;
      height: 48%;
    }
    li:nth-child(2) {
      left: 0;
      top: 0;
      width: 28%;
      height: 26%;
    }
    li:nth-child(3) {
      left: 0;
      top: 26%;
      width: 14%;
      height: 26%;
    }
    li:nth-child(4) {
      left: 14%;
      bottom: auto;
      top: 26%;
      width: 14%;
      height: 26%;
    }
    li:nth-child(5) {
      left: 28%;
      top: 0;
      width: 21%;
      height: 52%;
    }
    li:nth-child(6) {
      right: 0;
      bottom: 0;
      width: 18%;
      height: 64%;
    }
    li:nth-child(7) {
      left: auto;
      right: 0;
      bottom: auto;
      top: 0;
      width: calc(36% - 180px);
      height: 36%;
    }
    li:nth-child(8) {
      height: 36%;
      left: 28%;
      bottom: 0;
      width: 36%;
      height: 27%;
    }
    li:nth-child(9) {
      left: 64%;
      bottom: 0;
      width: 18%;
      height: 27%;
    }
    li:nth-child(10) {
      display: block;
      left: 28%;
      top: 52%;
      width: 21%;
      height: 21%;
    }
    li:nth-child(11) {
      left: 49%;
      top: 36%;
      width: 33%;
      height: 37%;
    }
    li:nth-child(12) {
      right: auto;
      left: 49%;
      top: 0;
      width: 15%;
      height: 36%;
    }
  }

  @include bp($mobile) {
     .container.above {
        li:nth-child(3),
        li:nth-child(4),
        li:nth-child(9),
        li:nth-child(10) {
          width: 50%;
        }
     }
  }

  .container.above li.titles {
    position: absolute;
    top: 88px;
    left: 0;
    z-index: 2;
    opacity: 1;
    background: none;
    box-sizing: border-box;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(flex-end);
    padding: 0px 0 20px 25px;
    overflow: visible;
    opacity: 0;
    visibility: hidden;
    transition-delay: .3s;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    @include bp($mobile, true) {
      left: 54%;
      top: 17%;
      height: 23%;
    }

    @include bp($tablet, true) {
      left: 64%;
      top: 0;
      width: 18%;
      height: 36%;
    }
  }

  .titles {

    .subtitle {
      font-family: 'kelsobold', 'Breve Sans Text Book', sans-serif;
      font-size: 45px;
      text-transform: uppercase;
      line-height: 1.1;
      letter-spacing: 0.03em;
      color: $white;
      margin-bottom: .025em;

      @include bp($mobile) {
        font-size: 36px;
      }
    }
    .title {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.075em;
      margin-bottom: .25em;
      white-space: pre;

      @include bp($mobile) {
        font-size: 12px;
      }
    }

    .button {
      align-self: flex-start;
      font-family: Coupe-Medium, sans-serif;
      //background: $white;
      //padding: 6px 16px 5px;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: .055em;
      font-size: 13px;
      color: #000;

      &:hover {
        color: $white;
        //background: #000;
      }
    }
  }

  .video-bg, iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  video {

  }

  .video-bg {
    visibility: hidden;
    width: 300%; 
    left: -100%;
    height: 150%;
    top: -25%;
  }

  .icon-video {
    display: block;
    position: absolute;
    left: calc(50% - 36px);
    top: calc(50% - 36px);
    width: 72px;
    height: 72px;
    background: #fff;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;

    &:after {
      content: "";
      position: absolute;
      left: calc(50% - 7px);
      top: calc(50% - 12px);
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 20px solid $green;
    }
  }
}

.video-caption {
  background: #000;
  margin-bottom: 20px;

  .caption-text {
    padding: 25px 20px;
  }

  .caption-link {
    padding: 0 0 12px;
  }

  .fancybox-type-iframe & {
    .caption-link {
      padding: 0 20px 12px;
    }
  }

  .button {
    display: inline-block;
    background: rgba($white, .2);
    color: $white;
    letter-spacing: 0.035em;
    font-size: 14px;
    line-height: 1;
    padding: 6px 16px 5px;
  }

  a.button:hover {
    background: $white;
    color: #000;
  }

  span.button {
    background: none;
    padding: 0;
  }

  .entry-title {
    color: $red;
    font-size: 24px;
    font-family: 'Breve News Bold';
    font-weight: normal;
    margin-bottom: .3em;
  }

  .caption {
    font-size: 13px;
    letter-spacing: 0.025em;
  }

  @include bp($mobile, true) {
    @include flexbox();

    .caption-text {
      flex: 1;
    }

    .caption-link {
      @include flexbox();
      @include align-items(center);
      padding: 12px 20px;
    }

    .fancybox-type-iframe & {
      .caption-link {
        padding: 12px 20px;
      }
    }
  }
}

.fancybox-title-float-wrap {
  .video-caption {
    background: none;
    width: 100%;
    margin-bottom: 0;
  }

  .caption-text {
    padding: 15px 0 13px;
  }
}