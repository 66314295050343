.touch .home {
    #bgvid {
      display: none;
    }
    .mobile-view {
      display: block;
    }
}
.home {
  #bgvid {
    background: #000000 none no-repeat 50% 50%;
    background-size: cover;
    position: fixed; 
    right: 0; 
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    width: auto; 
    height: auto; 
    #video {
      position: static;
      width: auto !important;
      height: auto !important;
    }
    #video video,
    #video object {
      position: fixed; 
      right: 0; 
      bottom: 0;
      min-width: 100%; 
      min-height: 100%;
      width: auto;
      height: auto; 
      z-index: 0;
      @media print {
        display: none;
      }
    }
    .vjs-big-play-button,
    .vjs-control-bar,
    .vjs-control,
    .vjs-error-display,
    .vjs-caption-settings {
      display: none;
    }
  }
  #fullss {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    ul {
      list-style: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
     	backface-visibility: hidden;
      li {
        position: absolute;
        width: calc(100% + 300px);
        height: calc(100% + 300px);
        display: block;
        top: -150px;
        left: -150px;
        right: -150px;
        bottom: -150px;
        background: transparent none no-repeat 50% 50%;
        background-size: cover;
        transition-duration: 8s;
        transition-property: transform,margin,left,right,top,bottom;
        opacity: 0;
        transform: scale(1);
        transition-timing-function: linear;
        -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
       	backface-visibility: hidden;
        &:first-child {
          opacity: 1;
        }
        &.larger {
          transform: scale(1.1);
        }
        &.smaller {
          transform: scale(0.9);
        }
        &.left {
          right: auto;
          left: -50px;
          top: -50px;
          bottom: auto;
        }
        &.right {
          top: auto;
          left: auto;
          left: -50px;
          bottom: 0px;
        }
      }
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent none;
    @media print {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent url(../images/background-logo-tall.png) no-repeat 50% 50%;
      background-size: 100% auto;
      z-index: 0;
    }
    img {
      display: none;
    }
  }
  .full-height {
    background: transparent none;
    @include bp($mobile) {
      height: auto !important;
    }
    @media print {
      height: auto !important;
      min-height: 0;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  .static-wrap {
    position: absolute;
    width: 1377px;
    top: 0;
    left: 50%;
    margin-left: -688.5px;
    right: 0;
    bottom: 0;
    @include bp(1377px) {
      width: 100%;
      left: 0;
      margin-left: auto;
    }
    @include bp($mobile) {
      position: static;
      width: 100%;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin: 0;
    }
    @media print {
      position: relative;
      width: 100%;
      left: 0;
      margin: 0;
    }
  }
  .snippet {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 15%;
    right: 15%;
    z-index: 10;
    color: #b0b0b0;
    @media print {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      color: #000;
      max-width: 100%;
      padding: 0;
      a {
        color: #000;
      }
    }
    a {
      color: #fff;
    }
    .project-slideshow {
      position: absolute;
      z-index: 20;
      top: 46%;
      width: 50%;
      @include bp($mobile) {
        position: relative;
        background: #e6e6e6;
        width: 100%;
      }
      @media print {
        display: none;
      }
      a {
        color: #fff;
      }
      .dots, 
      ul {
        margin-left: -10%;
        float: left;
        clear: both;
        width: 110%;
        @include bp($tablet) {
          margin-left: 0;
        }
      }
      .dots {
        font-size: 0.78571428571429em;
        text-transform: uppercase;
        letter-spacing: 0.05769230769231em;
        @extend .BreveSansBold;
        .view {
          display: inline-block;
          margin-right: 15px;
          &:hover {
            color: #000;
          }
        }
        a:not(.view) {
          @include dot(6px);
          vertical-align: middle;
          margin-left: 4px;
          background: #fff;
          &:hover, 
          &.active {
            background-color: #000;
          }
        }
      }
      .plus {
        text-indent: -2000px;
        display: block;
        overflow: hidden;
        text-align: left;
        content: "";
        background: #000 url(../images/sprite.png) no-repeat -5px -5px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 20px;
        right: -20px;
        &:hover {
          background-color: $green;
        }
      }
      ul {
        list-style: none;
        position: relative;
        margin-bottom: 1.1em;
        li {
          opacity: 0;
          z-index: 0;
          display: none;
          padding-right: 22%;
          width: 100%;
          box-sizing: border-box;
          color: #fff;
          @include bp($mobile) {
            padding-right: 0;
          }
          h2 {
            font-size: 1.8em;
            letter-spacing: 0.02083333333333em;
            padding-bottom: 2px;
            line-height: 1.35em;
            display: block;
            margin-bottom: 0px;
            font-family: 'Breve News Bold';
          }
          h4 {
            text-transform: uppercase;
            margin-bottom: 0.4em;
            @extend .BreveSansMedium;
            font-size: 0.92857142857143em;
            font-weight: 400;
            letter-spacing: 0.02083333333333em;
          }
          p {
            line-height: 1.21428571428571em;
            @extend .BreveSansLight;
          }
          &.active {
            display: block;
            z-index: 2;
            opacity: 1;
          }
        }
      }
    }
    
    .entry, 
    article {
      width: 50%;
      box-sizing: border-box;
      padding: 0 5%;
      position: absolute;
      bottom: 0;
      margin: 0;
      height: 133px;
      background: rgba(0,0,0,0.75);
      h2 {
        color: #fff;
        text-transform: none;
        @extend .BreveNewsSemibold;
        font-size: 1.14285714285714em;
        letter-spacing: 0;
        line-height: 1.2em;
        margin-bottom: 0.7em;
      }
      .valign {
        @extend %vertical;
        @media print {
          top: 0;
          margin: 0;
          transform: translateY(0);
          width: 100%;
          max-width: 100%;
        }
      }
    }
    article {
      padding-right: 14%;
      left: 50%;
      @media print {
        left: 0;
        padding: 0;
        width: 100%;
      }
      h5, 
      .meta {
        font-size: 0.92857142857143em;
        text-transform: uppercase;
        letter-spacing: 0.05769230769231em;
        @extend .BreveSansBold;
        line-height: 1.775em;
        a {
          color: #b0b0b0;
        }
      }
      .meta {
       font-size: 0.78571428571429em;
       line-height: 1.35714285714286em;
      }
      .view {
        @extend %vertical;
        position: absolute;
        right: 60px;       
        text-transform: uppercase;
        color: $green;
        line-height: 1.2em;
        text-align: center;
        background: #000;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 20px;
        font-size: 0.78571428571429em;
        @extend .BreveSansBold;
        &:hover {
          background-color: $green;
          color: #ffffff;
        }
        @include bp($tablet) {
          right: 10%;
        }
        @media print {
          display: none;
        }
      }
    }
   .entry {
     z-index: 21;
     transition-duration: $timing;
     @media print {
       height: auto !important;
       width: 100% !important;
       left: auto;
       max-width: 100%;
       margin: 0;
       padding: 0;
     }
     p {
       margin: 0;
       line-height: 1.35714285714286em;
     }
     .reveal {
       display: none;
       a {
         color: $red;
         text-transform: uppercase;
         &:hover {
           color: #ffffff;
         }
       }
       @media print {
         display: block;
       }
     }
     &.hover {
      background: #000;
      height: 220px;
      .valign {
        top: 40px;
        transform: translateY(0);
      }
      .more:after {
        display: none;
      }
      .reveal {
        display: inline;
      }
      .more:before {
        display: none;
      }
     }
     .more {
       text-transform: uppercase;
       font-size: 0.78571428571429em;
       color: #b0b0b0;
       @media print {
         display: none;
       }
       span {
         display: none;
         @media print {
           display: block;
         }
       }
       &:after {
         content: "More";
       }
       &:before {
         content: "...";
         color: #b0b0b0;
       }
       &:hover {
         color: #fff;
       }
     }
   }
   @include bp(1377px) {
     left: 10%;
     right: 10%;
     article, 
     .entry {
       height: 182px;
     }
   }
   @include bp($tablet) {
     left: 30px;
     right: 30px;
     .entry {
        height: 182px;
        &.hover {
         height: 350px;
       }
     }
   }
   @include bp($mobile) {
     left: 0;
     right: 0;
     position: relative;
     background: #000;
     width: 100%;
     left: 0;
     right: 0;
     .entry, 
     article {
       width: 100%;
       float: none;
       position: relative;
       left: 0;
       height: auto;
       .valign {
         top: auto;
         transform: translateY(0);
       }
     }
     .entry {
       padding: 40px 20px;
       h2 {
         font-size: 1.6em;
         line-height: 1.3em;
       }
       .reveal {
         display: inline;
       }
       .more {
         display: none;
       }
       &.hover {
         height: auto;
         .valign {
           top: auto;
         }
       }
     }
     article {
       background: #fff;
       padding: 40px;
       text-align: center;
       .view {
         position: relative;
         top: auto;
         margin: auto;
         right: auto;
         display: block;
         transform: translateY(0);
       }
       .meta {
         margin-bottom: 2em;
       }
       a {
         color: #000;
       }
     }
   }
  }
  .mobile-view {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent none no-repeat 50% 0;
    background-size: cover;
    &.a {
      background-image: url(../images/background-home-desktop-a.jpg);
    }
    &.b {
      background-image: url(../images/background-home-desktop-b.jpg);
    }
    &.c {
      background-image: url(../images/background-home-desktop-c.jpg);
    }
    &.d {
      background-image: url(../images/background-home-desktop-d.jpg);
    }
    &.e {
      background-image: url(../images/background-home-desktop-e.jpg);
    }
    &.f {
      background-image: url(../images/background-home-desktop-f.jpg);
    }
  }
  @include bp($tablet) {
    .mobile-view {
      &.a {
        background-image: url(../images/background-home-touch-a.jpg);
      }
      &.b {
        background-image: url(../images/background-home-touch-b.jpg);
      }
      &.c {
        background-image: url(../images/background-home-touch-c.jpg);
      }
      &.d {
        background-image: url(../images/background-home-touch-d.jpg);
      }
      &.e {
        background-image: url(../images/background-home-touch-e.jpg);
      }
      &.f {
        background-image: url(../images/background-home-touch-f.jpg);
      }
    }
  }
  @include bp($mobile) {
    .bg {
      position: relative;
      height: 0px;
      padding-bottom: 113%;
      &:after {
        background: transparent url(../images/image-home-cover-mobile.png) no-repeat 50% 0;
        background-size: 100% auto;
        top: 25px;
      }
    }
    .mobile-view {
      display: block;
    }
    #bgvid {
      display: none;
    }
    .snippet {
      .project-slideshow {
        position: relative;
        top: auto;
        margin: auto;
        width: 100%;
        max-width: 100%;
        background: #e6e6e6;
        padding: 40px;
        box-sizing: border-box;
        p, 
        h4 {
          color: #676768;
        }
        ul li {
          color: #676768;
          h2 {
            text-transform: uppercase;
            font-size: 1em;
            font-family: 'Breve Sans Text SemiBold';
            letter-spacing: 0.07142857142857em;
            color: #000;
          }
        }
        .plus {
          display: none;
        }
        ul, 
        .dots {
          float: none;
          width: 100%;
          margin: 0;
        }
        .dots {
          text-align: center;
          padding: 10px 0 0;
          .view {
            display: block;
            text-align: left;
            width: 100%;
            color: #000;
            margin-bottom: 0.9em;
          }
          a:not(.view) {
            @include dot(12px);
            margin: 0 6px;
            background-color: #000;
            &.active {
              background-color: #fff;
            }
          }          
        }
      }
    }
  }
  .page-wrap {
    padding-top: 0;
  }
  @media print {
    .full-height .static-wrap .snippet {
      padding: 0;
      margin: 0;
      .entry.wysiwyg,
      article {
        width: 100% !important;
        height: auto !important;
        position: relative !important;
        top: auto;
        padding: 40px;
        left: 0;
        right: 0;
        bottom: auto;
        .valign {
          width: 100%;
          top: 0;
          margin: 0;
          transform: translateY(0);
        }
      }
    }
  }
}