.page-template-default {
  .default {
    width: 80%;
    max-width: 960px;
    margin: 0 auto;
    padding: 5% 0;
    article {
      width: 100%;
    }
  }
}

.page-id-12074 {
	background: #000;

	.default {
		color: #fff;
	}

	.wysiwyg {
		h1, h2, h3 {
			color: #fff;
		}
	}
}