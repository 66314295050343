.blog, .single-post, .search, .archive {
  #infscr-loading {
    width: 100%;
    clear: both;
  }
  .post-pagination {
    text-align: center;
    padding: 10px 0 30px;
    text-transform: uppercase;
    clear: both;
    width: 100%;
    overflow: hidden;
    visibility: hidden;
    @media print {
      display: none;
    }
    div {
      display:block;
      float: left;
      width: 50%;
      text-align: right;
      a {
        width: auto;
      }
      &.nav-next {
        text-align: left;
      }
    }
    a {
      color: $red;
      font-size: 0.82142857142857em;
      @extend .BreveSansMedium;
      letter-spacing: 0.04347826086957em;
      line-height: 1.5em;
      display: inline-block;
      width: 100px;
      text-align: right;
      margin: 0 13px;
      &:hover {
        color: #000;
      }
    }
    a[rel="next"] {
      text-align: left;
    }
    a[rel="prev"]:after,
    .nav-previous a:after,
    a[rel="next"]:before, 
    .nav-next a:before {
      font-family: 'fontello';
      height: 26px;
      width: 26px;
      border-radius: 50%;
      background: $red;
      color: #fff;
      text-align: center;
      margin: 0 7px;
      line-height: 26px;
      font-size: 1.2em;
      display: inline-block;
    }
    .nav-previous a:after,
    a[rel="prev"]:after {
      content: "\e807";
    }
    .nav-next a:before,
    a[rel="next"]:before {
      content: "\e805";
    }
  }
  .archive-title {
    @extend .BreveBlack;
    line-height: 1.3em;
    margin-bottom: 1.3em;
    font-size: 2.9em;
    em {
      @extend .BreveBlackItalic;
    }
    @include bp($mobile) {
      font-size: 2.25em;
      padding: 20px 20px 0;
      margin-bottom: 0.8em;
      em {
        display: block;
      }
    }
  }
  .posts {
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 4% 0;
    .post {
      p {
        text-align: center;
        padding: 0 11%;
        line-height: 1.28571428571429em;
        .more {
          display: none;
        }
      }
      a {
        color: #231f20;
      }
      p {
        margin: 10px 0;
      }
    }
    @include bp($mobile) {
      .post {
        float: none;
        display: inline-block;
        height: auto;
        padding-bottom: 5px;
        max-height: auto;
        min-height: 0;
      }
    }
  }
  .sort {
    position: relative;
    overflow: visible;
    @media print {
      display: none;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    .left-right-wrap {
      overflow: hidden;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 100%;
        background: url(../images/image-header-blog.png) no-repeat 50% 50%;
        background-size: auto 100%;
        height: 100%;
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include bp($mobile) {
          background-size: auto 101%;
          background-position: 50% 50%;
          display: none;
        }
      }
    }
    .left, 
    .right {
      position: relative;
      width: 50%;
      float: left;
      z-index: 0;
      background: transparent none no-repeat 50% 50%;
      background-size: cover;
      height: 0;
      padding-bottom: 33.5%;
      @include bp($tablet) {
        padding-bottom: 60%;
      }
      @include bp($mobile) {
        padding-bottom: 70%;
        float: none;
        width: 100%;
        &.right {
          display: none;
        }
      }
    }
    .left {
      height: 100%;
      z-index: auto;
      header {
        @extend %vertical;
        position: absolute;
        color: #fff;
        padding: 0 10%;
        z-index: 90;
        a {
          color: #fff;
        }
        h1 {
          font-size: 1.42857142857143em;
          @extend .BreveSansBlack;
          line-height: 1em;
          letter-spacing: 0.075em;
          text-transform: uppercase;
        }
        h2 {
          font-size: 3.57142857142857em;
          @extend .BreveNewsBold;
          line-height: 1.2em;
          em {
            @extend .BreveNewsBoldItalic;
          }
        }
        @include bp($mobile) {
          width: 200%;
          box-sizing: border-box;
          margin-top: 50px;
          h2 {
            font-size: 2.28571428571429em;
            margin-top: 9px;
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $red;
        opacity: 0.65;
      }
    }
    .right {
      float: right;
      overflow: hidden;
    }
  }
  #sidebar {
    background: #e6e6e6;
    list-style: none;
    text-align: center;
    position: relative;
    z-index: 90;
    clear: both;
    color: #676768;
    text-transform: uppercase;
    @extend .coupbold;
    font-size: 1em;
    box-sizing: border-box;
    height: 62px;
    @include bp($mobile) {
      height: auto;
    }
    @media print {
      display: none;
    }
    > li {
      padding: 16px 0 15px;
      position: relative;
      display: inline-block;
      width: calc(100% / 4 - 4px);
      line-height: 1.8em;
      vertical-align: middle;
      box-sizing: border-box;
      height: 62px;
      @include bp($mobile) {
        height: auto;
      }
      ul {
        display: block;
        max-height: 0;
        transition-duration: 0.5s;
        overflow: hidden;
        opacity: 0;
        left: -10000px;
        transition-property: opacity,max-height;
      }
      &:hover {
        cursor: pointer;
        ul {
          left: 0;
          opacity: 1;
          display: block;
          max-height: 900px;
          z-index: 1;
        }
      }
    }
    .rss a {
      color: #676768;
      &:before {
        font-family: 'fontello';
        content: "\e803";
        color: #000;
        display: inline-block;
        margin-right: 5px;
        font-size: 1.2em;
      }
      &:hover {
        color: #000;
      }
    }
    ul {
      list-style: none;
      position: absolute;
      background: #000;
      left: 0;
      padding: 10px;
      box-sizing: border-box;
      display: none;
      top: 100%;
      width: 100%;
      a {
        display: block;
        padding: 11px 10px 9px;
        color: #fff;
        text-align: left;
        line-height: 1.1em;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
    form {
      text-align: left;
      width: 85%;
      border-bottom: 1px solid #000;
      position: relative;
      max-width: 255px;
      margin: 0 auto 6px;
      p:before {
        width: 15px;
        height: 20px;
        display: inline-block;
        color: #fff;
        font-size: 1.2em;
        font-family: 'fontello';
        content: "\e80f";
      }
      input[type="text"] {
        border: none;
        outline: none;
        background: transparent none;
        width: calc(85% - 24px);
        text-transform: uppercase;
        padding: 3px 0 6px;
        letter-spacing: 0.05769230769231em;
        font-size: 0.92857142857143em;
        display: inline-block;
        @extend .coup;
        color: #fff;
      }
      input[type="submit"], 
      button {
        position: absolute;
        color: #676768;
        font-size: 0.8em;
        text-align: center;
        border: none;
        outline: none;
        background: transparent none;
        top: 0;
        right: 0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        background: #404040;
        cursor: pointer;
        transition-duration: $timing;
        &:before {
          margin: 0;
          width: 100%;
          text-align: center;
        }
        &:hover {
          background-color: #000000 !important;
          color: #fff;
        }
      }
    }
  }
  @include bp($tablet) {
    #sidebar {
      > li {
        width: 14%;
      }
      > li.search {
        width: 40%;
      }
      > li.rss {
        width: 30%;
      }
    }
  }
}
.single-post {
  .sort {
    overflow: hidden;
  }
  .post-pagination {
    visibility: visible;
  }
  .meta {
    padding: 0 0 25px 0;
    text-align: left;
    a {
      display: inline-block;
      background: #e6e6e6;
      color: #181818;
      font-size: 13px;
      letter-spacing: 0.05769230769231em;
      @extend .coupbold;
      text-transform: uppercase;
      padding: 3px 9px 2px;
      margin: 0 10px 10px 0;
      line-height: 1em;
      &:hover {
        color: #e6e6e6;
        background-color: #181818;
      }
    }
  }
  .full-post {
    overflow: hidden;
    background: #1c1c1c;
    position: relative;
    &:before {
      // Width of article to fake continual background
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 67%;
      background: #fff;
      z-index: 0;
    }
    #sidebar, article.wysiwyg {
      overflow: hidden;
      margin: 0;
      box-sizing: border-box;
      position: relative;
      z-index: 10;
    }
    p:empty {
       display: none;
    }
    @include bp($mobile) {
      #sidebar {
        position: absolute;
        top: 0;
        height: 129px;
        border: none;
        left: 0;
        width: 100%;
        padding: 0;
        overflow: visible;
        .rss {
          display: none;
        }
        > li {
          display: none;
        }
        > li.search, 
        > li.categories, 
        > li.archives {
          display: block;
          height: 72px;
          box-sizing: border-box;
          padding: 0;
          line-height: 72px;
          background: #d7d7d7;
          color: #676768;
          &.active {
            background-color: #000;
            color: #fff;
          }
          a {
            color: #676768;
          }
        }
        > li:nth-child(odd) {
          background: #e6e6e6;
        }
        > li.ajax-results {
          display: block;
          padding: 0;
          #results {
            padding: 0;
            background: #000;
            ul {
              padding: 20px;
            }
          }
        }
        > li.search {
          height: auto;
          padding: 15px 30px;
          line-height: 1em;
          background: #000;
        }
      }
    }
  }
  #sidebar {
    background: #000;
    color: #a2a2a2;
    width: 33%;
    float: right;
    clear: none;
    border-bottom: 1px solid #343434;
    padding-top: 18px;
    height: auto;
    @media print {
      display: none;
    }
    li {
      display: block;
      float: left;
      width: 50%;
      height: auto;
      box-sizing: border-box;
      background: #1c1c1c;
      transition-duration: $timing;
      form {
        border-color: #fff;
        width: 100%;
        max-width: 100%;
      }
      a {
        color: #a2a2a2;
        &:hover {
          color: #fff;
        }
      }
      &:hover,
      &.active {
        color: #fff;
        a {
          color: #fff;
        }
        ul {
          display: none;
        }
      }
      &.search, 
      &.rss {
        background: #000;
        text-align: left;
        width: 100%;
        padding: 13px 12%;
        float: none; 
        cursor: default;  
      }
      &.ajax-results {
        width: 100%;
        background: #000;
        float: none;
        clear: both;
        padding: 0 12%;
        text-align: left;
        line-height: 1.46153846153846em;
        font-size: 0.92857142857143em;
        ul {
          position: relative;
          top: auto;
          left: auto;
          margin: 0;
          padding: 16% 0;
          display: block;
          width: 100%;
          max-width: 100%;
          opacity: 1;
          max-height: none;
          li {
            width: 100%;
            float: none;
            background: transparent;
            color: #a2a2a2;
            @extend .BreveSansBook;
            text-transform: none;
            &:hover, 
            &:hover a {
              background: transparent;
            }
            a {
              color: #a2a2a2;
              &:hover {
                color: #a2a2a2;
                span.meta {
                  color: $green;
                }
              }
            }
          }
        }
        article {
          text-transform: none;
          margin-bottom: 2.9em;
          @extend .BreveSansBook;
          a {
            color: #a2a2a2;
          }
          p {
            font-size: 1em;
            line-height: 1.46153846153846em;
          }
          .more {
            display: inline-block;
            color: #fff;
            text-transform: uppercase;
            font-size: 0.92307692307692em;
            @extend .BreveSansSemiBold;
          }
          a:hover {
            background: transparent;
            color: #fff;
          }
        }
        h2 {
          color: #fff;
          @extend .BreveBlack;
          line-height: 1.3em;
          margin-bottom: 0.3em;
        }
        .meta {
          color: $red;
          text-transform: uppercase;
          display: block;
          transition-duration: 0.3s;
          transition-property: color;
          @extend .BreveSansSemiBold;
          font-size: 1.07692307692308em;
          letter-spacing: 0.07142857142857em;
          line-height: 1.2em;
          margin-bottom: 0.4em;
          padding-bottom: 0;
        }
      }
    }
    li:nth-child(3n) {
      background: #212121;
    }
    .rss {
      margin-bottom: 20px;
      a {
        color: #a2a2a2;
        &:before,
        &:hover {
          color: #fff;
        }
      }
    }
  }
  article.wysiwyg {
    width: 67%;
    float: left;
    background: #fff;
    color: #6d6e71;
    .entry, header {
      padding: 0 15%;
      @media print {
        padding: 0;
      }
    }
    @media print {
      float: none;
      width: 100%;
    }
    .entry {
      padding: 0;
      max-width: 650px;
      width: 70%;
      margin: 0 auto;
      @media print {
        width: 100%;
        max-width: 100%;
        float: none;
        padding: 0;
        margin: 0;
      }
      p, 
      .alignnone {
        margin-bottom: 1.5em;
      }
    }
    .share {
      background: #e6e6e6;
      text-transform: uppercase;
      text-align: center;
      padding: 16px 0 1px;
      box-sizing: border-box;
      min-height: 59px;
      @media print {
        display: none;
      }
      .at-share-tbx-element {
        &:before {
          content: "Share this post";
          font-size: 0.92857142857143em;
          @extend .coupbold;
          letter-spacing: 0.07692307692308em;
          color: #000;
          display: inline-block;
          vertical-align: middle;
          line-height: 2.7em;
        }
      }
      .at-share-btn {
        display: inline-block;
        float: none;
        vertical-align: top;
        margin: 0 6% 0 6%;
        position: relative;
        line-height: 30px;
        cursor: pointer;
        background: transparent none !important;

        &:first-child {
          //margin-left: 0;
        }
        &:before {
          font-family: 'fontello';
          color: #000;
          opacity: 0.4;
          //font-size: 2em;
          font-size: 20px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          text-align: center;
          transition-duration: $timing;
        }
        &:hover {
          &:before {
            opacity: 1;
          }
        }
        > span {
          visibility: hidden;
        }
        &.at-svc-twitter {
          &:before {
            content: "\e800";
          }
        }
        &.at-svc-facebook {
          &:before {
            content: "\e802";
          }
        }
        &.at-svc-google_plusone_share {
          &:before {
            font-family: 'mail-g';
            content: "\e800";
          }
        }
        &.at-svc-email {
          &:before {
            font-family: 'mail-g';
            content: "\e809";
          }
        }
      }
    }
    header {
      text-align: center;
      padding-top: 7.5%;
      h1 {
        color: #000;
        font-size: 2.14285714285714em;
        line-height: 1.2em;
        letter-spacing: 0.00166666666667em;
        @extend .BreveNewsBold;
        @include bp($mobile) {
          font-size: 1.85714285714286em;
        }
      }
      time {
        display: block;
        color: $red;
        text-transform: uppercase;
        letter-spacing: 0.07142857142857em;
        @extend .BreveSansBold;
        white-space: pre;
        margin-bottom: 1.4em;
      }
      .author {
        text-transform: uppercase;
        color: $red;
        margin-bottom: 1.9em;
        font-size: 0.82142857142857em;
        @extend .BreveSansMedium;
        letter-spacing: 0.04347826086957em;
        line-height: 1.5em;
        img {
          width: 116px;
          height: auto;
          display: block;
          border-radius: 50%;
          margin: 0 auto 10px;
        }
      }
    }
    @include bp($mobile) {
      width: 100%;
      padding: 150px 20px 30px;
      .share {
        display: none;
      }
      .entry, 
      header {
        padding: 0;
      }
      .entry {
        width: 100%;
      }
    }
  }
}
.blog, 
.archive.category, 
.archive.date, 
.search {
  #sidebar {
    li {
      letter-spacing: 0.05357142857143em;
      padding-top: 19px;
      vertical-align: top;
    }
    > li ul {
      font-size: 12px;
      @include bp($tablet) {
        min-width: 200px;
      }
    }
    li.categories, 
    li.archives {
      &:hover {
        background-color: #000;
        color: #fff;
      }
      li {
        padding-top: 0;
      }
    }
    li.ajax-results {
      display: none;
    }
    li.search {
      padding-bottom: 8px;
      form {
        p:before {
          color: #000;
        }
        input[type="submit"],
        button {
          background-color: #fff;
          color: #bababa;
          &:hover {
            background-color: #000;
            color: #fff;
          }
        }
        input[type="text"] {
          color: #676768;
        }
        @include placeholder {
          color: #676768;
        }
      }
    }
  }
  @include bp($mobile) {
    .sort {
      overflow: visible;
      #sidebar {
        position: relative;
        bottom: 0px;
        background: #000;
        width: 100%;
        overflow: visible;
        z-index: 100;
        > li {
          display: none;
        }
        > li.search, 
        > li.categories, 
        > li.archives {
          display: block;
          width: 50%;
          float: left;
          height: 72px;
          padding: 0;
          line-height: 72px;
          box-sizing: border-box;
          background: #e6e6e6;
          color: #676768;
          position: static;
          ul {
            width: 100%;
            left: 0;
            right: 0;
            margin-left: 0px;
            margin-top: 0px;
            box-sizing: border-box;
            padding: 20px 0;
            right: auto;
            left: -10000px;
            li {
              padding: 0;
              line-height: 1.2em;
              text-align: left;
              width: auto;
              display: block;
            }
          }
          &:hover ul {
            max-height: 0;
          }
        }
        > li.categories, 
        > li.archives {
          &:hover,
          &.active {
            background: #000;
            color: #fff;
          }
        }
        > li:nth-child(odd) {
          background: #d7d7d7;
        }
        > li.ajax-results {
          display: block;
          padding: 0;
          width: 100%;
          #results {
            padding: 0;
            background: #000;
            ul {
              padding: 20px;
              display: block;
              width: 100%;
              left: 0;
              max-height: none;
              opacity: 1;
              li {
                min-height: 0;
                padding: 0;
                a {
                  padding: 11px 10px 9px;
                  text-transform: none;
                  //@extend .BreveSansBook;
                  font-family: 'Breve Sans Text Book',sans-serif;
                  font-weight: 400;
                  letter-spacing: 0;
                  font-size: 13px;
                  background: transparent none;
                  color: #a2a2a2;
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        > li.rss {
          clear: both;
          display: block;
          width: 100%;
          float: none;
          overflow: hidden;
          height: 0;
          padding: 0;
        }
        > li.search {
          width: 100%;
          padding: 16px 30px 10px;
          float: none;
          line-height: 1em;
          height: auto;
          background: #000;
          form {
            border-color: #fff;
            padding: 0;
            width: 100%;
            max-width: 100%;
            p:before {
              display: none;
            }
            input[type="text"] {
              padding: 6px 0 9px;
            }
            button {
              background: #404040;
            }
          }
        }
      }
    }
    .sort:after {
      bottom: 130px;
      height: auto;
    }
  }
}





