img {
  max-width: 100%;
  height: auto;
}

%vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.social {
  a {
    text-align: left;
    text-indent: -2000px;
    width: 21px;
    height: 21px;
    display: inline-block;
    position: relative;
    font-size: 1.5em;
    margin: 0 0 0 8px;
    font-weight: 400;
    &:before {
      font-weight: 400;
      text-indent: 0;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      text-align: center;
    }
    @include bp($mobile) {
      margin: 0 3px;
      width: 25px;
      height: 25px;
      &:before {
        margin: 0;
      }
    }
  }
}

.full-height {
  // Start height really tall, with black background
  height: 1500px;
  background: #000 none no-repeat 50% 50%;
  background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: 10;
  min-height: 675px;
  
  @media print {
    height: auto !important;
    opacity: 1 !important;
  }
  &.done {
    overflow: visible;
    .group:after {
      bottom: -35px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 1;
    z-index: 10;
    transition-property: opacity;
    transition-duration: 1s;
    @media print {
      display: none;
    }
  }
  &.open {
    &:after {
      opacity: 0;
    }
    .group {
      z-index: 20;
    }
  }
  &.ready {
    &:after {
      z-index: -1;
    }
  }
  .group {
    position: absolute;
    bottom: -100%;
    left: 15%;
    right: 15%;
    color: #000;
    transition-duration: 1s;
    transition-delay: .5s;
    transition-property: bottom;
    &.collapsable {
      transition-duration: .4s;
      transition-delay: 0s;
      .addthis_native_toolbox {
        opacity: 0;
        transition-duration: .4s;
        transition-property: opacity;
      }
      &:hover {
        .addthis_native_toolbox {
          opacity: 1;
        }
      }
      @include bp($mobile) {
        bottom: 0 !important;
        .addthis_native_toolbox {
          opacity: 1;
        }
      }
    }
    @include bp(1377px, true) {
      width: 978px;
      left: 50%;
      right: auto;
      margin-left: -489px;
    }
    @media print {
      position: relative;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
    }
    h1 {
      @extend .BreveBlack;
      color: #000;
      em {
        @extend .BreveBlackItalic;
      }
    }
    .text {
      text-align: left;
      text-indent: -3000px;
      width: 100%;
      padding-bottom: 11%;
      height: 0;
      margin: 0 0 -2px;
      background: rgba(0,0,0,0.28) none no-repeat 50% 0;
      background-size: 102% auto;
      @include bp($mobile) {
        border-top: 10px solid #fff;
      }
      @media print {
        text-indent: 0;
        background: none;
        padding: 0;
        margin: 0;
        font-size: 2.4em;
        display: block;
        clear: both;
        height: auto;
        text-align: center;
      }
    }
    p {
      @extend .BreveSansBook;
      font-size: 1.07142857142857em;
      line-height: 1.46666666666667em;
    }
    &:after {
      content: "";
      width: 0px;
      height: 0px;
      border-top: 35px solid #fff;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      position: absolute;
      bottom: 0;
      left: 14.25%;
      margin-left: -35px;
      transition-duration: $timing;
      transition-property: bottom;
      @media print {
        display: none;
      }
    }
    .bg {
      position: relative;
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      margin: 0 auto;
      img.text {
        width: 100%;
        display: block;
      }
      .content {
        background: #fff;
        padding: 8px 78px 10px;
        text-align: center;
        p, 
        h2, 
        h3,
        h4,
        h5 {
          opacity: 0;
          transition-delay: 1.5s;
          transition-duration: 1s;
        }
      }
    }
  }
  &.ready .group {
    bottom: 0;
    .bg .content {
      p, 
      h2, 
      h3,
      h4,
      h5 {
        opacity: 1;
      }
    }
  }
  @include bp($tablet) {
    .group {
      left: 9%;
      right: 9%;
      .bg .content {
        padding: 10px 40px 30px;
      }
    }
  }
  @include bp($mobile) {
    min-height: 500px;
    .group {
      left: 0;
      right: 0;
      &:after {
        left: 50%;
        bottom: -25px;
      }
      .bg {
        text-align: left;
        .content {
          padding: 10px 40px 20px;
        }
      }
    }
  }
  .slideshow {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @media print {
      display: none;
    }
    
    .video-container {
      width: 100%;
    	position: relative;
    	padding-bottom: 56.25%; /* 16:9 */
    	height: 0;
      margin: 0;
  
      iframe {
      	position: absolute;
      	top: 0;
      	left: 0;
      	width: 100%;
      	height: 100%;
      }
    }
    
    ul {
      list-style: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      li {
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        width: 100%;
        display: block;
        background: none no-repeat 50% 50%;
        background-size: cover;
        overflow: hidden;
        
        &:first-child {
          left: 0;
        }
        
        &.video {
          background: rgba(#fff,0.15);
        }
        
        &.inline {
          .video-wrap {
            max-width: 1200px;
            margin: auto;
            top: 50%;
            transform: translateY(-50%);
            position: relative;
          }
        }
        &.background {
          /*
          .video-wrap {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            object-fit: cover;
          }
          */
          .video-container, 
          .video-wrap {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0;
          }
          
          .video-container {
            
            iframe {
              width: 100vw;
              height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
              min-height: 100%;
              min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    
    
  }
}
.page-template-page-our-firm,
.post-type-archive-expertise {
  @include bp($mobile) {
    .full-height {
      height: auto !important;
      min-height: 0;
      .slideshow {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56%;
      }
      .group {
        position: relative;
        
      }
    }
  }
}
.testimonial {
  color: #fff;
  @extend %vertical;
  position: absolute;
  width: 50%;
  right: 0;
  box-sizing: border-box;
  @media print {
    display: none;
  }
  h2, 
  p {
    width: 100%;
    padding: 0 12% 0 30%;
    box-sizing: border-box;
  }
  h2 {
    color: $red;
    font-size: 2.49642857142857em;
    line-height: 1.14306151645207em;
    margin: .5em 0;
    @extend .BreveBlack;
    &:before {
      content: "\201C";
      width: 30px;
      box-sizing: border-box;
      padding-right: 5px;
      text-align: right;
      float: left;
      margin-left: -20px;
    }
    &:after {
      content: "\201D";
    }
  }
  p {
    @extend .BreveSansBold;
    text-transform: uppercase;
    text-align: right;
    font-size: 0.85714285714286em;
    line-height: 1.45em;
    letter-spacing: 0.03571428571429em;
  }
}
.testimonials {
  overflow: hidden;
  background: #fff;
  position: relative;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../images/background-split-logo.png) no-repeat 0 0;
    background-size: 100% auto;
    z-index: 0;
  }
  .testimonial {
    padding: 0 4% 0 11%;
    z-index: 10;
    h2 {
      padding: 15% 0 0;
      color: #94cdd9;
      font-size: 2.75vw;
    }
    p {
      padding: 0;
      color: #000;
    }
  }
  img {
    width: 50%;
    display: block;
    height: auto;
  }
  @include bp($mobile) {
    img {
      float: none;
      width: 100%;
    }
    .testimonial {
      width: 100%;
      position: relative;
      top: auto;
      transform: translateY(0);
      padding: 40px 30px;
      h2 {
        font-size: 2em;
        padding-top: 0;
      }
    }
    &:after {
      display: none;
    }
  }
}
.values {
  background: #e6e6e6;
  overflow: hidden;
  height: 310px;
  @media print {
    height: auto;
    width: 100%;
  }
  h2 {
    float: left;
    width: 25%;
    color: #fff;
    background: #000;
    height: 310px;
    text-transform: uppercase;
    text-align: center;
    @extend .BreveSansBlack;
    font-size: 2.14285714285714em;
    line-height: 1.16666666666667em;
    letter-spacing: 0.025em;
    box-sizing: border-box;
    span {
      @extend %vertical;
      display: block;
      padding: 0 20%;
      @media print {
        transform: translateY(0);
        top: 0;
        padding: 0;
      }
    }
    @media print {
      float: none;
      width: 100%;
      height: auto;
      line-height: 1.3em;
    }
  }
  ul {
    list-style: none;
    width: 75%;
    background: #e6e6e6;
    box-sizing: border-box;
    @extend %vertical;
    float: right;
    padding: 0 10px 0 60px;
    overflow: hidden;
    @media print {
      float: none;
      width: 100%;
      top: 0;
      margin: 0;
      transform: translateY(0);
      margin: 0;
      padding: 0;
    }
    li {
      width: 20%;
      float: left;
      box-sizing: border-box;
      padding: 0 23px 500px;
      margin-bottom: -500px;
      border-left: 1px solid #fff;
      color: #676768;
      font-size: 0.92857142857143em;
      line-height: 1.38461538461538em;
      h5 {
        text-transform: uppercase;
        font-size: 1.07692307692308em;
        @extend .BreveSansSemiBold;
        letter-spacing: 0.14em;
        margin: 0.4em 0;
        color: #000;
      }
      @media print {
        float: none;
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
      }
    }
    li:first-child {
      border: none;
    }
  }
  @include bp($mobile) {
    height: auto;
    h2 {
      width: 100%;
      float: none;
      height: auto;
      font-size: 1.42857142857143em;
      padding: 50px 20px 30px;
      span {
        transform: translateY(0);
        top: auto;
        padding: 0;
      }
    }
    ul {
      width:100%;
      float: none;
      transform: translateY(0);
      top: auto;
      background: $red;
      color: #fff;
      margin: 0;
      left: 0;
      right: 0;
      padding: 0;
      li {
        width: 100%;
        float: none;
      }
    }
  }
}


.member,
.project, 
.post {
  margin: 20px 40px;
  width: 255px;
  display: block;
  float: left;
  vertical-align: top;
  /*
  transition-duration: $timing;
  transition-property: height;
  */
  @include bp($mobile) {
    margin-left: 10px;
    margin-right: 10px;
  }
  > a {
    display: block;
  }
  .img {
    position: relative;
    display: block;
    margin-bottom: 30px;
    background: #000;
    img {
      display: block;
      width: 100%;
      height: auto;
      transition-duration: $timing;
    }
    &:after {
      content: "";
      background: #000 url(../images/sprite.png) no-repeat 0 0;
      width: 60px;
      height: 60px;
      display: block;
      transform: scale(.7,.7);
      transform-origin: 50% 50%;
      position: absolute;
      left: 50%;
      margin: -30px auto -30px -30px;
      bottom: 0;
    }
  }
  h2 {
    font-size: 1.14285714285714em;
    line-height: 1.6em;
    color: #000;
    @extend .BreveNewsSemibold;
    letter-spacing: 0.03125em;
  }
  h4 {
    color: #8c8c8c;
    font-size: 0.92857142857143em;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0.2em 0 1em;
    @extend .BreveSansMedium;
    letter-spacing: 0.07692307692308em;
    line-height: 1.3em;
    &.client {
      color: #000;
    }
  }
  h2, 
  h4 {
    transition-duration: $timing;
    transition-property: color;
  }
  button {
    width: 60px;
    height: 60px;
    background: url(../images/sprite.png) no-repeat 0 0;
    display: block;
    border: none;
    outline: none;
    transform: rotate(45deg);
    text-indent: -2000px;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }
  .entry {
    display: block;
    visibility: hidden;
    background: $red;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 14% 0 18%;
    overflow: hidden;
    @extend .BreveSansLight;
    @include bp($tablet) {
      padding: 0 35px;
    }
    @media print {
      position: relative;
      padding: 0;
    }
    p {
      margin-bottom: 1.3em;
    }
    a {
      color: #fff;
    }
    .social a {
      color: #fff;
      margin: 0 8px 0 0;
    }
    img {
      float: left;
      width: 30%;
      height: auto;
      display: block;
      margin: 65px 0 40px;
    }
    .bio {
      width: 70%;
      box-sizing: border-box;
      padding: 65px 47px 65px;
      float: right;
      text-align: left;
      @include bp($tablet) {
        padding-right: 0;
      }
      @media print {
        float: none;
        width: 100%;
        padding: 0;
      }
    }
  } 
  &.active {
    .entry {
      visibility: visible;
    }
  }
  &:hover {
    .img img {
      opacity: 0.8;
    }
  }
}
.member {
  @include bp($mobile) {
    float: none;
    display: inline-block;
  }
}
.project {
  height: 370px;
  @media print {
    height: auto;
    float: none;
    display: inline-block;
  }
}
.post {
  height: 450px;
}
.project, 
.post {
  .img {
    margin-bottom: 10px;
    &:after {
      display: none;
    }
  } 
  h4 {
    margin-bottom: 0;
  }
  a {
    &:hover {
      h2 {
        color: $red;
      }
    }
    &:after {
      content: "View";
      background: #fff url(../images/sprite.png) no-repeat -60px -15px;
      width: 60px;
      height: 40px;
      display: block;
      margin: 0 auto;
      box-sizing: border-box;
      text-transform: uppercase;
      color: #000;
      font-size: 0.85142857142857em;
      @extend .coup;
      padding-top: 26px;
      letter-spacing: 0.06291946308725em;
    }
  } 
}
.next, 
.previous {
  width: 30px;
  height: 30px;
  color: #5e5e5d;
  text-indent: -2000px;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  @media print {
    display: none;
  }
  &:before {
    content: "";
    text-indent: 0;
    font-family: 'fontello';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: block;
    line-height: 30px;
    text-align: center;
    font-size: 1.75em;
  }
  &:hover {
    color: #fff;
  }
}
.next {
  right: 7px;
  &:before {
    content: "\e805";
  }
}
.previous {
  left: 7px;
  &:before {
    content: "\e807";
  }
}
.all {
  color: $red;
  padding: 4% 0;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.05428571428571em;
  @extend .BreveSansSemiBold;
  letter-spacing: 0.06775067750678em;
  @media print {
    display: none;
  }
  &:after {
    content: "\e805";
    font-family: 'fontello';
    color: #fff;
    font-size: 1em;
    text-align: center;
    display: inline-block;
    background: $red;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    line-height: 26px;
    margin-left: 12px;
  }
  @include bp($tablet) {
    padding: 58px 10px 54px;
  }
  @include bp($mobile) {
    padding: 50px 10px 39px;
    &:after {
      display: block;
      margin: 10px auto 0;
    }
  }
}
.js-mobile-ss {
  .dots {
    display: none;
  }
  @include bp($tablet) {
    .js-slide-wrap {
      position: relative;
      .js-slide {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        display: none;
        &.active {
          display: block;
          position: relative !important;
        }
      }
    }
    .dots {
      text-align: center;
      padding: 10px 0;
      display: block;
      a {
        @include dot(12px);
        background-color: #fff;
        opacity: 0.2;
        margin: 0 7px;
        &:hover, 
        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.page-template-page-our-firm-php .people, 
.post-type-archive-projects .projects, 
.blog .posts, 
.archive.category .posts, 
.archive.date .posts, 
.search .posts {
  width: 1675px;
  margin: 0 auto;
  @include bp(1675px) {
    width: 1340px;
  }
  @include bp(1340px) {
    width: 1005px;
  }
  @include bp(1005px) {
    width: 670px;
  }
  @include bp($tablet) {
    width: 670px;
  }
  @include bp($mobile) {
    width: auto;
  }
}

@include bp($mobile) {
  .post-type-archive-projects .values .dd, 
  .page-template-page-our-firm-php .filters .dd {
    position: relative;
    display: block;
    .selected {
      background: $red;
      display: block;
      text-align: center;
      margin: 0;
      padding: 8px 15%;
      box-sizing: border-box;
      font-size: 1.07142857142857em;
      //@extend .BreveSansSemiBold;
      font-family: 'Breve Sans Text SemiBold',sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 0.05em;
      cursor: pointer;
      &:after {
        content: "\e805";
        font-family: 'fontello';
        transform: rotate(90deg);
        font-size: 1.75em;
        opacity: 0.6;
        margin-left: 13px;
        vertical-align: bottom;
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 20px;
      }
    }
    ul {
      padding: 0 0 20px;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      display: none;
      z-index: 999;
      background: $red;
      li {
        width: 70%;
        text-align: left;
        margin: 0 auto;
        display: block;
        float: none;
        padding: 0;
        font-size: 11px;
        min-height: 0;
        //@extend .BreveSansSemiBold;
        font-family: 'Breve Sans Text SemiBold',sans-serif;
        font-weight: 400;
        a {
          letter-spacing: 0.09090909090909em;
          font-size: 1em;
          color: #fff;
          display: block;
          padding: 4px 0;
          &:hover, 
          &.active {
            color: #000;
          }
        }
      }
    }
    &.open {
      ul {
        display: block;
      }
    }
  }
}