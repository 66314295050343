@import url("//hello.myfonts.net/count/2c9160");
@import url("//hello.myfonts.net/count/2cb42b");

@font-face {
  font-family: 'Breve News Bold';
  src: url('../fonts/BreveNews-Bold.eot'); 
  src: url('../fonts/BreveNews-Bold.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-Bold.woff') format('woff'), 
       url('../fonts/BreveNews-Bold.ttf')  format('truetype'), 
       url('../fonts/BreveNews-Bold.svg#d5cef4c06b7402d37b50fe8c6417dd77') format('svg'); 
       
  font-style:   normal;
  font-weight:  700;
}
.BreveNewsBold {
  font-family: 'Breve News Bold';
  font-weight: 400;
}

@font-face {
  font-family: 'Breve News Bold Italic';
  src: url('../fonts/BreveNews-BoldItalic.eot'); 
  src: url('../fonts/BreveNews-BoldItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-BoldItalic.woff') format('woff'), 
       url('../fonts/BreveNews-BoldItalic.ttf')  format('truetype'), 
       url('../fonts/BreveNews-BoldItalic.svg#6712881254ba0d27d8781351472496a1') format('svg'); 
       
  font-style:   italic;
  font-weight:  700;
}
.BreveNewsBoldItalic {
  font-family: 'Breve News Bold Italic';
  font-weight: 400;
}

@font-face {
  font-family: 'Breve News Book';
  src: url('../fonts/BreveNews-Book.eot'); 
  src: url('../fonts/BreveNews-Book.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-Book.woff') format('woff'), 
       url('../fonts/BreveNews-Book.ttf')  format('truetype'), 
       url('../fonts/BreveNews-Book.svg#4bb2e4e67250ec557db32656c9f69dcd') format('svg'); 
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Breve News Book Italic';
  src: url('../fonts/BreveNews-BookItalic.eot'); 
  src: url('../fonts/BreveNews-BookItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-BookItalic.woff') format('woff'), 
       url('../fonts/BreveNews-BookItalic.ttf')  format('truetype'), 
       url('../fonts/BreveNews-BookItalic.svg#f69eff976b09054cd6b53e01a8c120c4') format('svg'); 
       
  font-style:   italic;
  font-weight:  400;
}

@font-face {
  font-family: 'Breve News Light';
  src: url('../fonts/BreveNews-Light.eot'); 
  src: url('../fonts/BreveNews-Light.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-Light.woff') format('woff'), 
       url('../fonts/BreveNews-Light.ttf')  format('truetype'), 
       url('../fonts/BreveNews-Light.svg#7dcaef58453b0b1a554e09d28f57ab17') format('svg'); 
       
  font-style:   normal;
  font-weight:  200;
}

@font-face {
  font-family: 'Breve News Light Italic';
  src: url('../fonts/BreveNews-LightItalic.eot'); 
  src: url('../fonts/BreveNews-LightItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-LightItalic.woff') format('woff'), 
       url('../fonts/BreveNews-LightItalic.ttf')  format('truetype'), 
       url('../fonts/BreveNews-LightItalic.svg#d9001343b9d8a4e3ec6321986336c3a3') format('svg'); 
       
  font-style:   italic;
  font-weight:  200;
}

@font-face {
  font-family: 'Breve News Medium';
  src: url('../fonts/BreveNews-Medium.eot'); 
  src: url('../fonts/BreveNews-Medium.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-Medium.woff') format('woff'), 
       url('../fonts/BreveNews-Medium.ttf')  format('truetype'), 
       url('../fonts/BreveNews-Medium.svg#87a5fcbfad93c79ea32c62a0c4073694') format('svg'); 
       
  font-style:   normal;
  font-weight:  400;
}
.BreveNewsMedium {
  font-family: 'Breve News Medium';
  font-weight: 400;
}

@font-face {
  font-family: 'Breve News Medium Italic';
  src: url('../fonts/BreveNews-MediumItalic.eot'); 
  src: url('../fonts/BreveNews-MediumItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-MediumItalic.woff') format('woff'), 
       url('../fonts/BreveNews-MediumItalic.ttf')  format('truetype'), 
       url('../fonts/BreveNews-MediumItalic.svg#25a0269fb45019687e6d9d2f7f98d6a3') format('svg'); 
       
  font-style:   italic;
  font-weight:  400;
}

@font-face {
  font-family: 'Breve News SemiBold';
  src: url('../fonts/BreveNews-SemiBold.eot'); 
  src: url('../fonts/BreveNews-SemiBold.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-SemiBold.woff') format('woff'), 
       url('../fonts/BreveNews-SemiBold.ttf')  format('truetype'), 
       url('../fonts/BreveNews-SemiBold.svg#fac1ffc09db190fa378a69a6cf255f6e') format('svg'); 
       
  font-style:   normal;
  font-weight:  700;
}
.BreveNewsSemibold {
  font-family: 'Breve News SemiBold';
  font-weight: 400;
}

@font-face {
  font-family: 'Breve News SemiBold Italic';
  src: url('../fonts/BreveNews-SemiBoldItalic.eot'); 
  src: url('../fonts/BreveNews-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-SemiBoldItalic.woff') format('woff'), 
       url('../fonts/BreveNews-SemiBoldItalic.ttf')  format('truetype'), 
       url('../fonts/BreveNews-SemiBoldItalic.svg#beb376e5b794c1c597e5736d3c839d8d') format('svg'); 
       
  font-style:   italic;
  font-weight:  700;
}


@font-face {
  font-family: 'Breve Sans Text Black Italic';
  src: url('../fonts/BreveSansText-BlackItalic.eot'); 
  src: url('../fonts/BreveSansText-BlackItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-BlackItalic.woff') format('woff'), 
       url('../fonts/BreveSansText-BlackItalic.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-BlackItalic.svg#10bec26828e7dde883373c847efaad0a') format('svg'); 
       
  font-style:   italic;
  font-weight:  400;
}

@font-face {
  font-family: 'Breve Sans Text Black';
  src: url('../fonts/BreveSansText-Black.eot'); /* IE9 Compat Modes */
  src: url('../fonts/BreveSansText-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/BreveSansText-Black.woff') format('woff'), /* Modern Browsers */
       url('../fonts/BreveSansText-Black.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/BreveSansText-Black.svg#4780912f20b2f38d8527dbb8a3a20a91') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

.BreveSansBlack {
  font-family: 'Breve Sans Text Black',sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: 'Breve Sans Text Bold';
  src: url('../fonts/BreveSansText-Bold.eot'); 
  src: url('../fonts/BreveSansText-Bold.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-Bold.woff') format('woff'), 
       url('../fonts/BreveSansText-Bold.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-Bold.svg#c1f4938a3ce652fe909084ababfbf1e0') format('svg'); 
       
  font-style:   normal;
  font-weight:  700;
}
.BreveSansBold {
  font-family: 'Breve Sans Text Bold';
  font-weight: 400;
}

@font-face {
  font-family: 'Breve Sans Text Bold Italic';
  src: url('../fonts/BreveSansText-BoldItalic.eot'); 
  src: url('../fonts/BreveSansText-BoldItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-BoldItalic.woff') format('woff'), 
       url('../fonts/BreveSansText-BoldItalic.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-BoldItalic.svg#5301f06b8236528074e359443b507307') format('svg'); 
       
  font-style:   italic;
  font-weight:  700;
}


@font-face {
  font-family: 'Breve Sans Text Book Italic';
  src: url('../fonts/BreveSansText-BookItalic.eot'); 
  src: url('../fonts/BreveSansText-BookItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-BookItalic.woff') format('woff'), 
       url('../fonts/BreveSansText-BookItalic.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-BookItalic.svg#718c93929c12970be76937803dd064ca') format('svg'); 
       
  font-style:   italic;
  font-weight:  400;
}
.BreveBookItalic {
  font-family: 'Breve Sans Text Book Italic';
  font-weight: 400;
}

@font-face {
  font-family: 'Breve Sans Text Light';
  src: url('../fonts/BreveSansText-Light.eot'); 
  src: url('../fonts/BreveSansText-Light.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-Light.woff') format('woff'), 
       url('../fonts/BreveSansText-Light.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-Light.svg#0704be1add7b2a4beef99db346d52bdb') format('svg'); 
       
  font-style:   normal;
  font-weight:  200;
}
.BreveSansLight {
  font-family: 'Breve Sans Text Light';
  font-weight: 400;
}


@font-face {
  font-family: 'Breve Sans Text Light Italic';
  src: url('../fonts/BreveSansText-LightItalic.eot'); 
  src: url('../fonts/BreveSansText-LightItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-LightItalic.woff') format('woff'), 
       url('../fonts/BreveSansText-LightItalic.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-LightItalic.svg#87093800ded569016099bf3c91e1f133') format('svg'); 
       
  font-style:   italic;
  font-weight:  200;
}

@font-face {
  font-family: 'Breve Sans Text Medium';
  src: url('../fonts/BreveSansText-Medium.eot'); 
  src: url('../fonts/BreveSansText-Medium.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-Medium.woff') format('woff'), 
       url('../fonts/BreveSansText-Medium.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-Medium.svg#1553a373da7c31b8cb6eb9eac1b3aff5') format('svg'); 
       
  font-style:   normal;
  font-weight:  400;
}
.BreveSansMedium {
  font-family: 'Breve Sans Text Medium';
  font-weight: 400;
}

@font-face {
  font-family: 'Breve Sans Text Medium Italic';
  src: url('../fonts/BreveSansText-MediumItalic.eot'); 
  src: url('../fonts/BreveSansText-MediumItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-MediumItalic.woff') format('woff'), 
       url('../fonts/BreveSansText-MediumItalic.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-MediumItalic.svg#36d58cddabdc68b735b0a2c7523cce32') format('svg'); 
       
  font-style:   italic;
  font-weight:  400;
}

@font-face {
  font-family: 'Breve Sans Text SemiBold Italic';
  src: url('../fonts/BreveSansText-SemiBoldItalic.eot'); 
  src: url('../fonts/BreveSansText-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-SemiBoldItalic.woff') format('woff'), 
       url('../fonts/BreveSansText-SemiBoldItalic.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-SemiBoldItalic.svg#5d1730a3b669f809ee1e3bb0e851b678') format('svg'); 
       
  font-style:   italic;
  font-weight:  700;
}
.BreveSansSemiBoldItalic {
  font-family: 'Breve Sans Text SemiBold Italic';
}

@font-face {
  font-family: 'Breve Sans Text Book';
  src: url('../fonts/BreveSansText-Book.eot'); 
  src: url('../fonts/BreveSansText-Book.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-Book.woff') format('woff'), 
       url('../fonts/BreveSansText-Book.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-Book.svg#cdadcda32268d023f245647ef6a867a2') format('svg'); 
       
  font-style:   normal;
  font-weight:  400;
}
.BreveSansBook {
  font-family: 'Breve Sans Text Book',sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: 'Breve News Black';
  src: url('../fonts/BreveNews-Black.eot'); 
  src: url('../fonts/BreveNews-Black.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-Black.woff') format('woff'), 
       url('../fonts/BreveNews-Black.ttf')  format('truetype'), 
       url('../fonts/BreveNews-Black.svg#aaba440ac66efd8efe889698d2eaed9b') format('svg'); 
       
  font-style:   normal;
  font-weight:  400;
}
.BreveBlack {
  font-family: 'Breve News Black',serif;
  font-weight: 400;
}

@font-face {
  font-family: 'Breve News Black Italic';
  src: url('../fonts/BreveNews-BlackItalic.eot'); 
  src: url('../fonts/BreveNews-BlackItalic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveNews-BlackItalic.woff') format('woff'), 
       url('../fonts/BreveNews-BlackItalic.ttf')  format('truetype'), 
       url('../fonts/BreveNews-BlackItalic.svg#6a0f8da48f08e4a26c3dc35b89434b2e') format('svg'); 
       
  font-style:   italic;
  font-weight:  400;
}
.BreveBlackItalic {
  font-family: 'Breve News Black Italic',serif;
  font-weight: 400;
}

@font-face {
  font-family: 'Breve Sans Text SemiBold';
  src: url('../fonts/BreveSansText-SemiBold.eot'); 
  src: url('../fonts/BreveSansText-SemiBold.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/BreveSansText-SemiBold.woff') format('woff'), 
       url('../fonts/BreveSansText-SemiBold.ttf')  format('truetype'), 
       url('../fonts/BreveSansText-SemiBold.svg#426298308a02f5f12d8b5b365c3feb35') format('svg'); 
       
  font-style:   normal;
  font-weight:  700;
}  
.BreveSansSemiBold {
  font-family: 'Breve Sans Text SemiBold',sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: 'Coupe-Medium';src: url('../fonts/2C9160_0_0.eot');src: url('../fonts/2C9160_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2C9160_0_0.woff2') format('woff2'),url('../fonts/2C9160_0_0.woff') format('woff'),url('../fonts/2C9160_0_0.ttf') format('truetype');
}
 
.coup {
  font-family: 'Coupe-Medium',sans-serif;
  font-weight: 400;
}
  
@font-face {
  font-family: 'Coupe-Bold';src: url('../fonts/2CB42B_0_0.eot');src: url('../fonts/2CB42B_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2CB42B_0_0.woff2') format('woff2'),url('../fonts/2CB42B_0_0.woff') format('woff'),url('../fonts/2CB42B_0_0.ttf') format('truetype');
}
.coupbold {
  font-family: 'Coupe-Bold';
  font-weight: 400;
} 

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?60952033');
  src: url('../fonts/fontello.eot?60952033#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff?60952033') format('woff'),
       url('../fonts/fontello.ttf?60952033') format('truetype'),
       url('../fonts/fontello.svg?60952033#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'check';
  src: url('../fonts/check.eot?32594385');
  src: url('../fonts/check.eot?32594385#iefix') format('embedded-opentype'),
       url('../fonts/check.woff?32594385') format('woff'),
       url('../fonts/check.ttf?32594385') format('truetype'),
       url('../fonts/check.svg?32594385#check') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'mail-g';
  src: url('../fonts/mail-g.eot?56285404');
  src: url('../fonts/mail-g.eot?56285404#iefix') format('embedded-opentype'),
       url('../fonts/mail-g.woff?56285404') format('woff'),
       url('../fonts/mail-g.ttf?56285404') format('truetype'),
       url('../fonts/mail-g.svg?56285404#mail-g') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?60952033#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-twitter:before { content: '\e800'; } /* '' */
.icon-instagramm:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-rss:before { content: '\e803'; } /* '' */
.icon-youtube:before { content: '\e804'; } /* '' */
.icon-right-open-big:before { content: '\e805'; } /* '' */
.icon-mail:before { content: '\e806'; } /* '' */
.icon-left-open-big:before { content: '\e807'; } /* '' */
.icon-linkedin-rect:before { content: '\e808'; } /* '' */
.icon-location:before { content: '\e809'; } /* '' */
.icon-right-circle:before { content: '\e80a'; } /* '' */
.icon-minus:before { content: '\e80d'; } /* '' */
.icon-plus:before { content: '\e80e'; } /* '' */
.icon-search:before { content: '\e80f'; } /* '' */
.check-ok:before { content: '\e806'; } /* '' */
.icon-gplus:before { content: '\e800'; } /* '' */
.icon-mail-bg:before { content: '\e801'; } /* '' */