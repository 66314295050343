.page-template-page-connect-php {
  .page-wrap {
    position: relative;
  }
  article {
    padding: 4% 7%;
    box-sizing: border-box;
    width: 50%;
    position: relative;
    //@extend %wysiwyg;
    .wpcf7 img.ajax-loader {
      display: block;
      margin: 20px auto 0;
    }
    @media print {
      width: 100%;
      padding: 0;
    }
    form {
      padding-top: 21px;
      padding-bottom: 0;
      margin-bottom: 0;
      position: relative;
      @media print {
        display: none;
      }
      @include bp($tablet) {
        padding-bottom: 15px;
      }
      @include bp($mobile) {
        padding-top: 10px;
      }
      .pcf7-form-control-wrap {
        position: relative;
      }
      div.wpcf7-response-output {
        position: absolute;
        bottom: 0;
        color: $green;
        font-size: 0.98571428571429em;
        @extend .BreveBookItalic;
        font-style: italic;
        letter-spacing: 0.04em;
        left: 0;
        border: none;
        margin: 0 0 20px;
        padding: 0;
        &.wpcf7-mail-sent-ok {
          border: none;
        }
      }
      .wpcf7-not-valid-tip {
        display: none;
      }
      p {
        margin: 0 0 1em;
        overflow: hidden;
        position: relative;
        @include bp($mobile) {
          margin-bottom: 0.5em;
        }
      }
      input[type="text"],
      input[type="email"],
      textarea {
        position: relative;
        z-index: 0;
        border: none;
        outline: none;
        border-bottom: 1px solid #000;
        display: block;
        margin-bottom: 2em;
        width: 100%;
        color: #000;
        padding: 8px 0;
        letter-spacing: 0.15em;
        font-size: 0.92857142857143em;
        @extend .BreveSansBold;
        &.wpcf7-not-valid {
          border-color: $red;
          color: $red;
        }
        &.placeheld {
          color: #000;
          opacity: 1;
          text-transform: uppercase;
        }
      }
      label[for="your-message"] {
        color: #000;
        padding: 8px 0;
        display: block;
        letter-spacing: 0.15em;
        font-size: 0.92857142857143em;
        @extend .BreveSansBold;
        text-transform: uppercase;
      }
      .your-email, 
      .phone {
        display: inline-block;
        width: 48%;
      }
      .your-email {
        float: left;
        @include bp($mobile) {
          width: 100%;
          float: none;
          margin-bottom: 1em;
        }
      }
      .phone {
        float: right;
        @include bp($mobile) {
          width: 100%;
          float: none;
          margin-bottom: 1em;
        }
      }
      textarea {
        outline: none;
        resize: none;
        height: 129px;
        color: #000;
        margin: 0;
        padding: 10px;
        border: none;
        border: 1px solid #000000;
        line-height: 1.25em;
        width: 100%;
        box-sizing: border-box;
        @include bp($mobile) {
          margin-bottom: 0.6em;
        }
      }
      .your-message {
        display: block;
        overflow: hidden;
      }
      .label,
      #mc4wp-checkbox {
        text-transform: uppercase;
        color: #949494;
        letter-spacing: 0.1em;
        font-size: 0.71428571428571em;
        @extend .BreveSansBold;
        line-height: 1.5em;
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 150px);
        a {
          color: #000;
          &:hover {
            color: $red;
          }
        }
        @include bp($mobile) {
          line-height: 1.1em;
        }
      }
      #mc4wp-checkbox {
        position: absolute;
        top: -3000px;
        left: -3000px;
        width: 50px;
        height: 50px;
      }
      label[for="subscribe"] {
        width: 20px;
        height: 20px;
        background: #000;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        position: relative;
        z-index: 10;
        cursor: pointer;
        &.checked {
          &:before {
            display: inline-block;
            margin-left: 3px;
            font-family: "check"; 
            color: #fff;
            content: "\e806";
          }
        }
      }
      .subscribe {
        position: absolute;
        top: 0px;
        left: -10px;
        z-index: 0;
        .wpcf7-list-item-label {
          display: none;
        }
      }
      input[type="submit"], 
      button {
        float: right;
        background: transparent url(../images/icon-submit-arrow.png) no-repeat 100% 50%;
        text-transform: uppercase;
        outline: none;
        border: none;
        color: $red;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-size: 0.92857142857143em;
        @extend .BreveSansBold;
        padding: 0 31px 0 0;
        height: 25px;
        box-sizing: border-box;
        width: 94px;
        line-height: 25px;
        position: relative;
        transition-duration: $timing;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
      @include placeholder {
        color: #000;
        opacity: 1;
        text-transform: uppercase;
      }
    }
  }
  
  .column {
    width: 100%;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    p a {
      color: #000;
      &:hover {
        color: $red;
      }
    }
    @include bp($mobile) {
      padding: 0 30px;
    }
  }
  .follow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 190px; // Height of footer
    width: 50%;
    background: transparent none no-repeat 50% 50%;
    background-size: cover;
    @media print {
      display: none;
    }
    h2 {
      display: none;
    }
    .social {
      background: $red;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 84px 4%;
      text-align: center;
      a {
        color: #000;
        opacity: 0.5;
        font-size: 2em;
        margin: 0 10px;
        vertical-align: top;
        overflow: hidden;
        display: inline-block;
        width: 30px;
        height: 30px;
        &:hover {
          opacity: 1;
        }
        &:before {
          margin: 0;
          text-align: center;
        }
      }
      &:before {
        content: "Follow Us";
        color: #fff;
        display: inline-block;
        margin: 0 10px;
        vertical-align: baseline;
        font-size: 3.21428571428571em;
        @extend .BreveBlack;
      }
    }
  }
  @include bp($tablet) {
    .follow {
      display: block;
      width: 100%;
      float: none;
      height: 1170px;
      clear: both;
      position: relative;
      .social {
        bottom: auto;
        top: 0;
        padding: 84px 40px;
        
      }
    }
    article {
      width: 100%;
      padding: 80px 18%;
    }
  }
  @include bp($mobile) {
    article {
      padding: 40px 7% 4%;
      form {
        padding-bottom: 20px;
      }
    }
    .follow {
      height: 670px;
      .social {
        padding: 40px;
        &:before {
          display: block;
          width: 100%;
          line-height: 1em;
          margin: 0 0 0.4em;
        }
      }
    }
  }
  .item {
    margin: 0 0 2em;
    font-size: 0.92857142857143em;
    width: 50%;
    float: left;
    padding-right: 20px;
    box-sizing: border-box;
    &.left {
      float: left;
      clear: left;
    }
    &.right {
      float: right;
    }
    @include bp($mobile) {
      float: none;
      width: 100%;
    }
    h5 {
      color: $red;
      text-transform: uppercase;
      @extend .BreveSansBlack;
      letter-spacing: 0.1em;
      line-height: 1.3em;
      margin: 0 0 0.3em;
      font-size: 1.07692307692308em;
      position: relative;
      &:before {
        content: "";
        height: 24px;
        display: inline-block;
        width: 0;
      }
      a {
        &:before {
          width: 17px;
          height: 24px;
          content: "";
          background: transparent url(../images/sprite.png) no-repeat -81px -78px;
          display: inline-block;
          margin-right: 5px;
          @include bp($mobile) {
            position: absolute;
            left: -26px;
          }
        }
        @media print {
          padding: 0;
          &:before {
            display: none;
          }
        }
      }
    }
    address, 
    .name-address {
      font-style: normal;
      display: inline-block;
      line-height: 1.2em;
    }
    p {
      margin: 0 0 0.1em;
    }
  }
  #thankyou {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(116,205,217,0.92);
    text-align: center;
    padding: 40px;
    z-index: 99;
    display: none;
    h1 {
      color: #000000;
      font-size: 2em;
      line-height: 1.25em;
      letter-spacing: 0.01785714285714em;
      @extend .BreveNewsSemibold;
      width: 80%;
      max-width: 392px;
      margin: 112px auto 0;
      &:before {
        content: "";
        display: block;
        width: 86px;
        height: 86px;
        border-radius: 50%;
        margin: 0 auto 15px;
        background: #ffffff url(../images/logo-mackey-mitchell.svg) no-repeat 18px 60%;
      }
    }
    button {
      background: transparent url(../images/button-close.png) no-repeat 50% 50%;
      cursor: pointer;
      width: 18px;
      height: 18px;
      border: none;
      outline: none;
      position: absolute;
      top: 18px;
      text-indent: -2000px;
      overflow: hidden;
      display: block;
      right: 18px;
    }
  }
}