footer[role="main"] {
  height: 190px;
  background: #000;
  color: #fff;
  padding: 70px 60px 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 100;
  @media print {
    padding: 30px 0 0;
    height: auto;
  }
  .connect {
    width: 40%;
    max-width: 330px;
    position: absolute;
    right: 60px;
    text-align: right;
    @media print {
      display: none;
    }
    a {
      color: #808080;
      &:hover {
        color: #ffffff;
      }
    }
    form {
      font-size: 0.92857142857143em;
      @extend .coup;
      margin: 0 0 16px 0;
      text-align: left;
      @media print {
        display: none;
      }
      @include placeholder {
        color: #656565;
      }
      p {
        height: 35px;
        background: #1a1a1a;
      }
      &.sent {
        p {
          font-size: 0.92857142857143em;
          @extend .coup;
          color: #a2a2a2;
          background: transparent none;
          text-align: right;
          text-transform: uppercase;
          box-sizing: border-box;
          padding-top: 9px;
        }
      }
      .response {
        line-height: 1.1em;
        color: $red;
        padding-bottom: 10px;
        box-sizing: border-box;
        &#mce-error-response {
          margin-top: -35px;
          min-height: 35px;
        }
      }
      input, 
      button {
        display: inline-block;
        vertical-align: top;
        background: transparent none;
        border: none;
        outline: none;
        @extend .coup;
      }
      input {
        width: calc(100% - 40px);
        height: 35px;
        font-size: 1em;
        box-sizing: border-box;
        padding-left: 10px;
        text-transform: uppercase;
        color: #a2a2a2;
        letter-spacing: 0.05769230769231em;
      }
      button {
        background: #313131;
        color: #656565;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        margin-right: 8px;
        cursor: pointer;
        @extend %vertical;
        &:hover {
          background: #656565;
          color: #313131;
        }
      }
    }
  }
  .copyright {
    font-size: 0.78571428571429em;
    margin-left: 70px;
    @extend .BreveBookItalic;
    letter-spacing: 0.06818181818182em;
    font-style: italic;
    clear: both;
    width: 60%;
    @media print {
      width: 100%;
      text-align: left;
      margin: 0;
    }
  }
  .bulk {
    width: calc(100% - 350px);
    float: left;
    max-width: 790px;
    font-size: 0.78571428571429em;
    box-sizing: border-box;
    padding-left: 70px;
    position: relative;
    @media print {
      float: none;
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
    address {
      text-transform: uppercase;
      font-size: 1.18181818181818em;
      @extend .coup;
      letter-spacing: 0.05em;
      font-style: normal;
      padding-top: 6px;
      color: #a2a2a2;
      a {
        color: #a2a2a2;
        &:hover {
          color: #fff;
        }
      }
      br {
        display: none;
      }
      span {
        display: inline-block;
        border-right: 1px solid #a2a2a2;
        padding-right: 9px;
        margin-right: 9px;
        line-height: 0.8em;
        &:last-child {
          padding-right: 0;
          border: none;
        }
      }
    }
    .logo {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #fff;
      display: block;
      text-indent: -2000px;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      @media print {
        display: none;
      }
      &:before {
        width: 30px;
        height: 30px;
        content: "";
        overflow: hidden;
        display: block;
        top: 50%;
        position: absolute;
        left: 50%;
        margin: -15px auto auto -15px;
        background: transparent url(../images/logo-mackey-mitchell.svg) no-repeat 0 50%;
        background-size: 145px auto;
      }
    }
  }
  @include bp(1225px) {
    .bulk address {
      span:nth-child(2) {
        border: none;
      }
      br {
        display: block;
      }
    }
  }
  @include bp($tablet) {
    height: auto;
    padding-top: 38px;
    .bulk, 
    .connect {
      width: 460px;
      margin: 0 auto;
      float: none;
      position: relative;
      max-width: 460px;
    }
    .connect {
      padding-top: 40px;
      overflow: hidden;
      right: auto;
      width: 100%;
      max-width: 85%;
      text-align: center;
      form {
        width: 50%;
        float: none;
        display: inline-block;
        vertical-align: top;
      }
      .social {
        width: 100%;
        float: none;
        display: inline-block;
        vertical-align: top;
        padding-top: 9px;
        text-align: center;
        padding-left: 0px;
        box-sizing: border-box;
        a {
          margin-right: 5px;
          margin-left: 5px;
        }
        a:before {
          margin: 0;
        }
      }
    }
    .copyright {
      margin: 0 auto;
      text-align: center;
      padding: 20px 0 35px;
    }
  }
  @include bp($mobile) {
    height: auto;
    padding: 10% 20px;
    .bulk, 
    .connect {
      width: 100%;
      float: none;
      max-width: 100%;
      position: relative;
      right: auto;
    }
    .bulk {
      position: relative;
      margin-bottom: 2.7em;
      .logo {
        position: absolute;
        top: 50%;
        margin-top: -27px;
      }
      address {
        span {
          display: block;
          line-height: 1.4em;
          padding: 0;
          margin: 0;
          border: none;
        }
        br {
          display: none;
        }
      }
    }
    p.copyright {
      text-align: center;
      left: 0;
      margin: 0;
      padding: 0 20px;
      line-height: 1.63636363636364em;
      width: 100%;
      box-sizing: border-box;
    }
    .connect {
      text-align: center;
      margin-bottom: 1.5em;
      font-size: 1.2em;
      padding-top: 0;
      form {
        margin-bottom: 2em;
        @include placeholder {
          font-size: 12px;
          line-height: 1.5em;
        }
      }
      form, 
      .social {
        width: 100%;
        float: none;
      }
      .social {
        padding-top: 0;
      }
    }
  }
}