$white: #ffffff;
$red: #ed1c24;
$green: #74cdd9;
$blue: #2acae0;
$gray: #808080;

$transitiontime: .3s;

$gutter: 5%;

$tablet: 960px;
$desktop: 1200px;
$mobile: 767px;

$timing: 0.3s;