.post-type-archive-projects {
  .projects {
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 4% 0 ;
    z-index: 0;
    background: #fff;
    @media print {
      padding: 0;
      width: 100%;
    }
    @include bp($tablet) {
      padding: 6% 0;
    }
    @include bp($mobile) {
      .project {
        float: none;
        display: inline-block;
      }
    }
  }
  .group .bg {
    text-align: left;
    .text {
      background-image: url(../images/text-inspired.svg);
    }
  }
  .splits {
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @media print {
      display: none;
    }
    &:hover,
    &.open {
      li:before {
        opacity: 0.65;
      }
    }
    &:hover {
      li {
        width: calc(60% / 3);
      }
    }
    li {
      width: 25%;
      display: block;
      float: left;
      height: 100%;
      background: transparent none no-repeat 50% 50%;
      background-size: cover;
      transition-duration: $timing;
      transition-property: width;
      position: relative;
      z-index: 0;
      left: 0;
      @include bp(960px,true) {
        left: auto !important;
      }
      .effect {
        position: absolute;
        top: 0;
        left: 10%;
        right: 10%;
        bottom: 0;
        background: #000;
        display: block;
        z-index: 0;
        transition-duration: 0.4s;
        opacity: 1;
        transition-property: left,right,opacity;
      }
      &.close .effect {
        left: 0;
        right: 0;
        opacity: 0;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: #000;
        opacity: 0;
        transition-duration: $timing;
        transition-property: opacity;
      }
      &:hover,
      &.open {
        &:before {
          opacity: 0;
        }
      }
      &:hover {
        width: 40%;
        cursor: pointer;
      }
      .project-info {
        position: absolute;
        left: 50%;
        top: 20%;
        height: 20px;
        width: 20px;
        margin: -10px;
        overflow: hidden;
        transition-timing-function: linear;
        transition-duration: .2s;
        z-index: 0;
      }
      &.open {
        z-index: 1;
        .project-info {
          height: 250px;
          margin-top: -125px;
          margin-left: -125px;
          width: 250px;
          z-index: 1;
        }
        .plus {
          opacity: 0;
          z-index: 0;
        }
      }
      a {
        display: block;
        width: 250px;
        height: 250px;
        overflow: hidden;
        text-align: center;
        transition-duration: .2s;
        transition-property: margin,width,padding;
        opacity: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -125px auto auto -125px;
        span:not(.plus) {
          display: block;
          margin-top: 0px;
          background: #000;
          color: #fff;
          box-sizing: border-box;
          padding: 20px 25px 110px;
          overflow: hidden;
          width: 250px;
          height: 250px;
          position: relative;
          &:after {
            content: "view project";
            text-transform: uppercase;
            position: absolute;
            bottom: 28px;
            font-size: 0.79142857142857em;
            @extend .BreveSansBold;
            letter-spacing: 0.09025270758123em;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 2;
            transition-duration: $timing;
            transition-property: color;
          }
        }
        &:hover {
          span:after {
            color: $red;
          }
        }
        h4 {
          text-transform: uppercase;
          font-size: 0.9142857142857em;
          @extend .BreveSansMedium;
          letter-spacing: 0.09025270758123em;
          margin: 0.4em 0 1.2em;
        }
        h2 {
          font-size: 1.6em;
          //@extend .BreveNewsBold;
          font-family: 'Breve News Bold';
          display: block;
          line-height: 1.25em;
          margin: 0.5em 0 0;
          padding-bottom: 4px;
        }
        p {
          font-size: 0.92857142857143em;
          line-height: 1.15384615384615em;
          @extend .BreveSansLight;
        }
      }
      .plus {
        content: "";
        width: 60px;
        height: 60px;
        background: #000 url(../images/sprite.png) no-repeat 0 0;
        display: block;
        position: absolute;
        z-index: 2;
        left: 50%;
        margin-left: -30px;
        top: 20%;
        margin-top: -33px;
        text-indent: -2000px;
        overflow: hidden;
        transition-duration: $timing;
        transition-property: color, background-color,opacity;
        z-index: 1;
        &:hover {
          background-color: #fff;
          background-position: -120px 0;
        }
      }
      &.open a {
        opacity: 1;
      }
    }
    li:nth-child(1) {
      .project-info, 
      .plus {
        top: 39%;
        @include bp(960px,false) {
          top: 40%;
        }
        @include bp($mobile) {
          top: 50%;
        }
      }
    }
    li:nth-child(2) {
      .project-info, 
      .plus {
        top: 26%;
        @include bp(960px,false) {
          top: 40%;
        }
        @include bp($mobile) {
          top: 50%;
        }
      }
    }
    li:nth-child(3) {
      .project-info, 
      .plus {
        top: 45%;
        @include bp(960px,false) {
          top: 40%;
        }
        @include bp($mobile) {
          top: 50%;
        }
      }
    }
    li:nth-child(4) {
      .project-info, 
      .plus {
        top: 34%;
        @include bp(960px,false) {
          top: 40%;
        }
        @include bp($mobile) {
          top: 50%;
        }
      }
    }
  }
  @include bp(960px,false) {
    .full-height {
      .splits {
        li {
          position: absolute;
          top: 0;
          left: 100%;
          bottom: 0;
          width: 100%;
          &:before {
            opacity: 0;
          }
          &:first-child {
            left: 0;
          }
        }
      }
    }
  }
  @include bp($mobile) {
    .full-height {
      height: auto !important;
      min-height: 0;
      .group {
        position: relative;
      }
      .splits {
        position: relative;
        height: 0;
        padding-bottom: 70%;
      }
    }
  }
  .values {
    height: 225px;
    @media print {
      display: none;
    }
    h2 {
      height: 225px;
    }
    ul {
      padding-top: 19px;
      padding-left: 8%;
      padding-right: 0;
      max-width: 1200px;
      float: left;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      
      li {
        border: none;
        padding: 0 10px 15px 0;
        margin: 0;
        box-sizing: border-box;
        text-transform: uppercase;
        @extend .BreveSansMedium;
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.0625em;
        width: 13%;
        border-left: 2px solid $white;
        border-right: 2px solid $white;
        margin-right: -2px;
        text-align: left;
        padding: 0 8%;
        float: none;
        
        ul {
          padding: 0;
          margin: 0;
          float: none;
          width: 100%;
          display: block;
          top: 0;
          transform: translateY(0);
          
          li {
            border: none;
            padding: 5px 0 0;
            width: 100%;
            
            &:first-child {
              width: 100%;
            }
          }
        }
        
        &:first-child {
          border: none;
          padding-left: 0;
          padding-right: 0;
          justify-self: center;
          align-self: center;
        }
        
        &.with-markets {
          width: 58%;
          
          ul {
            columns: 2;
            
            li {
              
            }
          }
        }
        &.with-features {
          width: 29%;
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
        
        a {
          color: #676768;
          &:hover, &.active {
            color: #000;
          }
        }
      }
    }
    .selected {
      display: none;
    }
    @include bp($tablet) {
      h2 {
        width: 37%;
        span {
          padding: 0 25px;
        }
      }
      ul {
        width: 63%;
        padding: 40px;
        flex-wrap: wrap;
        
        li {
          border: none;
          padding: 0 10px 0 0;
          
          &:first-child {
            width: 100%;
            padding-bottom: 20px;
          }
          
          ul li {
            &:first-child {
              padding: 5px 0 0;
            }
          }
        
          &.with-markets {
            width: 70%;
            ul {
              //columns: 1;
            }
          }
          &.with-features {
            width: 30%;
          }
        }
      }
    }
    @include bp($mobile) {
      height: auto;
      overflow: visible;
      z-index: 5;
      position: relative;
      .selected {
        display: block;
      }
      h2 {
        height: auto;
        width: 100%;
      }
      .dd ul ul {
        position: relative;
        columns: 1;
        padding: 0;
        
        li {
          width: 100%;
        }
      }
      ul {
        width: 100%;
        padding-left: 60px;
        display: block;
        
        li {
          width: 100%;
          box-sizing: border-box;
          padding: 5px 10% 20px;
          
          &.with-markets, 
          &.with-features, 
          &:first-child {
            width: 100%;
            padding: 5px 10% 20px;
            
            ul {
              columns: 1;
            }
          }
          
          ul {
            display: block;
            position: relative;
            padding: 0;
            
            li {
              width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
.single-projects {
  .page-wrap {
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  .awards {
    position: absolute;
    top: 123px;
    right: 0;
    z-index: 900;
    text-align: right;
    width: 300px;
    transition-duration: $timing;
    transition-property: right;
    @include bp($mobile) {
      top: 20px;
    }
    > h2 {
      background: rgba(237,28,36,0.69);
      color: #fff;
      text-transform: uppercase;
      @extend .coupbold;
      font-size: 12px;
      letter-spacing: 0.0625em;
      display: inline-block;
      text-align: center;
      line-height: 1em;
      padding: 28px 0 25px;
      width: 200px;
      cursor: pointer;
    }
    .expandee {
      background: rgba(255,255,255,0.85);
      text-align: left;
      font-size: 11px;
      line-height: 1.36363636363636em;
      left: 0;
      position: absolute;
      width: 300px;
      overflow: hidden;
      transition-duration: $timing;
      .copy {
        padding: 23px 23px;
      }
      p {
        margin: 1.65em 0;
        strong,
        b {
          @extend .coupbold;
          font-size: 15px;
        }
        a strong,
        strong a {
          color: #000;
        }
      }
    }
    &.closed {
      right: 0px;
      .expandee {
        left: 100%;
      }
    }
  }
  .full-height {
    z-index: 100;
    overflow: hidden;
    .awards {
      background: rgba(237,28,36,0.69);
      font-size: 0.85714285714286em;
      text-transform: uppercase;
      @extend .coup;
      padding: 20px 25px;
      color: #fff;
      position: absolute;
      top: 8%;
      right: 0;
      z-index: 10;
      letter-spacing: 0.0625em;
      @media print {
        display: none;
      }
    }
    .group:after {
      display: none;
    }
    .dots {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      z-index: 10;
      text-align: center;
      a {
        @include dot(7px);
        background: #fff;
        margin: 0 4px;
        &:hover, 
        &.active {
          background-color: $red;
        }
      }
      @media print {
        display: none;
      }
    }
    .group .bg {
      background: rgba(0,0,0,0.75) none;
      text-align: left;
      padding: 40px 190px 40px 60px;
      @media print {
        padding: 0;
      }
      h1 {
        color: #fff;
        font-size: 2.5em;
        @media print {
          color: #000;
        }
      }
      h5 {
        color: #b0b0b0;
        text-transform: uppercase;
        letter-spacing: 0.07692307692308em;
        font-size: 0.92857142857143em;
        @extend .BreveSansBold;
        span:first-child {
          display: inline-block;
          padding-right: 9px;
          margin-right: 5px;
          line-height: 1em;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 2px;
            bottom: 0px;
            right: 0;
            border-right: 2px solid #878785;
            @include bp($mobile) {
              display: none;
            }
          }
        }
      }
    }
    
    @include bp($tablet) {
      .addthis_native_toolbox {
        left: 42px;
        top: 42px;
        margin: 0;
      }
    }
    @include bp($mobile) {
      overflow: visible;
      height: auto !important;
      .slideshow {
        position: relative;
        ul {
          position: relative;
          height: 0;
          padding-bottom: 70%;
        }
      }
      .next, 
      .previous {
        display: none;
      }
      .group {
        bottom: auto !important;
        position: relative;
        .bg {
          background: $green none;
          text-align: center;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 60px;
          left: 0;
          right: 0;
          h1 {
            line-height: 1em;
            font-size: 2.2em;
          }
          h5 {
            color: #000;
            line-height: 1.4em;
            span {
              border: none;
              display: block;
            }
            span:first-child {
              border: none;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
      
      .awards {
        display: none;
      }
      .addthis_native_toolbox {
        display: block;
        position: absolute;
        top: auto;
        transform: translateY(0);
        margin-bottom: -43px;
        right: auto;
        left: 50%;
        bottom: 0;
        margin-left: -43px;
      }
    }
  }
  
  .addthis_native_toolbox {
    @include dot(86px);
    background: #fff;
    text-align: center;
    color: $blue;
    text-indent: 0;
    text-transform: uppercase;
    box-sizing: border-box;
    padding-top: 28px;
    position: absolute;
    right: 90px;
    top: 50%;
    margin-top: -43px;
    font-size: 0.78571428571429em;
    line-height: 1.36363636363636em;
    letter-spacing: 0.09090909090909em;
    @extend .BreveSansBold;
    transition-duration: $timing;
    transition-property: background-color,color;
    @media print {
      display: none;
    }
    &:hover {
      color: #fff;
      background: $green;
    }
    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      .addthis_pill_style {
        width: 100%;
        display: block !important;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        .addthis_button_expanded {
          display: none !important;
          visibility: hidden;
        }
        .addthis_button_compact {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
        }
      }
    }
  }
  .three {
    background: #000;
    padding: 5% 0;
    ul {
      list-style: none;
      color: #b0b0b0;
      overflow: hidden;
      li {
        display: block;
        float: left;
        width: 33%;
        width: calc(100% / 3);
        box-sizing: border-box;
        padding: 2% 6%;
        padding-bottom: 500px;
        margin-bottom: -490px;
        border-left: 1px solid #434343;
        border-right: 1px solid #434343;
        margin-right: -1px;
        line-height: 1.78571428571429em;
        @media print {
          width: 100%;
          float: none;
          padding: 20px 0;
          margin: 0;
          border: none;
        }
        h2 {
          text-transform: uppercase;
          @extend .BreveSansBlack;
          font-size: 2.14285714285714em;
          letter-spacing: 0.025em;
          line-height: 1em;
          display: block;
          color: $green;
          padding-bottom: 16px;
          text-align: center;
          position: relative;
          margin-bottom: 0.7em;
          @media print {
            text-align: left;
          }
          &:after {
            content: "";
            background: #434343;
            height: 1px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 34%;
            right: 34%;
          }
        }
      }
      li:first-child {
        border-left: none;
        h2 {
          color: $red;
        }
      }
      li:last-child {
        border-right: none;
        h2 {
          color: $red;
        }
      }
    }
    @include bp($tablet) {
      padding: 167px 20% 0;
      position: relative;
      .dots {
        position: absolute;
        top: 70px;
        bottom: auto;
        left: 0;
        right: 0;
        a {
          width: 14px;
          margin: 0 11px;
          height: 14px;
        }
      }
      ul {
        li {
          border: none;
          padding: 0;
          margin: 0;
          h2 {
            text-align: left;
            padding-bottom: 0;
            &:after {
              display: none;
            }
          }
        }
      }
    }
    @include bp($mobile) {
      padding: 113px 5px 50px;
      position: relative;
      .dots {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        text-align: center;
        a {
          width: 12px;
          height: 12px;
          margin: 0 9px;
        }
      }
      ul {
        padding-top: 0px;
        li {
          width: 100%;
          float: none;
          border: none;
          h2 {
            text-align: center;
            font-size: 1.42857142857143em;
            padding: 0;
          }
        }
      }
    }
  }
  .project-detail {
    background: #000;
    color: #fff;
    overflow: hidden;
    text-align: center;
    padding: 4% 0 7%;
    > h1 {
      font-size: 4.35714285714286em;
      @extend .BreveBlack;
      letter-spacing: 0.00819672131148em;
      line-height: 1em;
      margin: 0 auto 1em;
      span {
        color: $red;
      }
    }
    @include bp($mobile) {
      padding: 0;
      > h1 {
        display: none;
      }
    }
  }
  .details-wrap {
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;
  }
  .details {
    list-style: none;
    margin: 0 auto;
    position: relative;
    height: 0;
    padding-bottom: 51%;
    padding-bottom: 51.440329218107%;
    li {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      cursor: pointer;
      z-index: 0;
      transition-duration: $timing;
      background: #000;
      button {
        content: "";
        background: #000 url(../images/sprite.png) no-repeat 0 0;
        border: none;
        outline: none;
        width: 60px;
        height: 60px;
        display: block;
        position: absolute;
        text-indent: -2000px;
        right: 50%;
        margin-right: -30px;
        z-index: 90;
        top: 50%;
        text-align: left;
        transition-duration: $timing;
        &:hover {
          cursor: pointer;
        }
      }
      &.open {
        cursor: default;
        left: 0 !important;
        width: 100% !important;
        z-index: 10;
        .quote {
          left: 50%;
          @include bp($mobile) {
            display: none;
          }
        }
        button {
          right: 0;
          margin-right: 0;
          top: 0 !important;
          background: #000 none;
          overflow: hidden;
          &:before {
            content: "";
            display: block;
            width: 20px;
            height: 1px;
            background: #000;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -10px;
            border-top: 1px solid #fff;
          }
        }
        figure figcaption {
          visibility: visible;
        }
        &:hover {
          button {
            background-color: #000;
          }
        }
      }
      &:hover {
        button {
          background-color: $green;
        }
      }
      .quote {
        background: #fff;
        width: 50%;
        left: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        box-sizing: border-box;
        padding: 5% 10%;
        color: $gray;
        line-height: 1.78571428571429em;
        @include bp($tablet) {
          padding: 5%;
        }
        p {
          margin-bottom: 1.2em;
          text-align: left;
        }
        h4 {
          text-transform: uppercase;
          color: #000;
          @extend .BreveSansBold;
          font-size: 1em;
          letter-spacing: 0.03571428571429em;
        }
        img {
          width: 180px;
          height: 180px;
          display: block;
          border-radius: 50%;
          margin: 0 auto 2em;
          @include bp($tablet) {
            width: 120px;
            height: 120px;
          }
        }
      }
      figure {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: auto;
          height: 100%;
          max-width: 1217px;
        }
        figcaption {
          display: block;
          position: absolute;
          bottom: 0px;
          box-sizing: border-box;
          padding: 27px 68px;
          background: #fff;
          color: #000;
          left: 10%;
          right: 10%;
          text-align: center;
          visibility: hidden;
        }
      }
    }
    li:nth-child(2) button {
      top: 10%;
    }
    li:nth-child(3) button {
      top: 80%;
    }
    li:nth-child(4) button {
      top: 55%;
    }
    li:nth-child(5) button {
      top: 20%;
    }
    &.col1 {
      li {
        width: 100%;
      }
    }
    &.col2 {
      li {
        width: calc(100% / 2);
      }
      li:nth-child(2) {
        left: 50%;
      }
    }
    &.col3 {
      li {
        width: calc(100% / 3);
      }
      li:nth-child(2) {
        left: calc(100% / 3 * 1);
      }
      li:nth-child(3) {
        left: calc(100% / 3 * 2);
      }
    }
    &.col4 {
      li {
        width: calc(100% / 4);
      }
      li:nth-child(2) {
        left: calc(100% / 4 * 1);
      }
      li:nth-child(3) {
        left: calc(100% / 4 * 2);
      }
      li:nth-child(4) {
        left: calc(100% / 4 * 3);
      }
    }
    &.col5 {
      li {
        width: calc(100% / 5);
      }
      li:nth-child(2) {
        left: calc(100% / 5 * 1);
      }
      li:nth-child(3) {
        left: calc(100% / 5 * 2);
      }
      li:nth-child(4) {
        left: calc(100% / 5 * 3);
      }
      li:nth-child(5) {
        left: calc(100% / 5 * 4);
      }
    }
    @include bp(mobile) {
      height: auto !important;
      padding: 0;
      li {
        width: 100% !important;
        float: none;
        position: relative !important;
        left: 0 !important;
        figure, 
        figure img {
          position: relative;
          width: 100%;
          height: auto;
        }
        button {
          display: none;
        }
      }
    }
  }
  .team {
    background: $red;
    overflow: hidden;
    height: 100%;
    position: relative;
    display: table;
    width: 100%;
    vertical-align: middle;
    .testimonial-wrap {
      left: 0;
      right: auto;
      position: relative;
      width: 50%;
      display: table-cell;
      height: 100%;
      vertical-align: middle;
      @media print {
        display: none;
      }
      .testimonial {
        display: table-cell;
        vertical-align: middle;
        padding: 60px 0;
        position: relative;
        top: auto;
        transform: translateY(0);
        width: 100%;
      }
      p, 
      h2 {
        padding: 0 18% 0 16%;
      }
      h2 {
        color: #fff;
      }
      p {
        color: #000;
      }
    }
    .people {
      background: $green;
      color: #fff;
      padding: 6% 0;
      width: 50%;
      height: 100%;
      display: table-cell;
      @media print {
        display: block;
        width: 100%;
        padding: 0;
        color: #000;
        height: auto;
      }
      > h2 {
        text-transform: uppercase;
        color: #000;
        text-align: center;
        font-size: 1.42857142857143em;
        @extend .BreveSansSemiBold;
        margin: 0 auto 1em;
        letter-spacing: 0.005em;
        @media print {
          text-align: left;
        }
      }
      ul {
        list-style: none;
        width: 55%;
        margin: 0 auto;
        @media print {
          width: 100%;
        }
        li {
          margin-bottom: 1.2em;
          line-height: 1.3em;
          h2 {
            font-size: 1.28571428571429em;
            @extend .BreveNewsMedium;
            display: inline-block;
            a {
              color: #fff;
              &:hover {
                color: #000;
              }
            }
          }
          h4 {
            display: inline-block;
            margin-left: 2px;
            text-transform: uppercase;
            letter-spacing: 0.05769230769231em;
            font-size: 0.92857142857143em;
          }
        }
      }
    }
    
    @include bp($tablet) {
      padding: 0;
      .testimonial-wrap {
        width: 100%;
        position: relative;
        top: auto;
        transform: translateY(0);
        padding: 100px 20% 70px;
        display: block;
        height: auto;
        box-sizing: border-box;
        .testimonial {
          width: 100%;
          display: block;
          padding-top: 0px;
          padding-bottom: 0px;
        }
        p, 
        h2 {
          padding: 0;
        }
      }
      .people {
        width: 100%;
        left: 0;
        display: block;
        padding: 80px 20px 60px;
        text-align: left;
        box-sizing: border-box;
        min-height: 0;
        ul {
          margin: 0 auto;
          padding: 0;
          width: 352px;
        }
      }
    }
    @include bp($mobile) {
      .testimonial-wrap {
        padding: 40px 30px;
        h2 {
          font-size: 1.74714285714286em;
        }
      }
      .people {
        text-align: center;
        padding: 40px 20px;
        ul {
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}
#at20mc {
  display: none !important;
  @include bp($tablet) {
    display: block !important;
  }
}





