.post-type-archive-expertise {
  background: #000;
  color: #fff;
  .group .bg {
    text-align: left;
    .text {
      background-image: url(../images/text-your-dream.svg);
    }
  }
  .ssi-slideshow {
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    text-align: center;
    .slides-nav {
      list-style: none;
      text-transform: uppercase;
      text-align: center;
      li {
        display: inline-block;
        padding: 0 14px;
        a {
          color: #515151;
        }
        a:hover,
        &.active a {
          color: #ababab;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .progress-container {
      position: relative;
      padding: 11px 0;
      display: inline-block;
      margin: 92px auto 40px;
      .progress {
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #74cdd9;
        width: 10%;
      }
    }
    .slides {
      width: 90%;
      max-width: 970px;
      margin: 0 auto;
      padding: 0 0 8.75%;
      position: relative;
      overflow: hidden;
      text-align: left;
      box-sizing: border-box;
      @media print {
        top: auto;
        padding: 0;
        width: 100%;
        max-width: 100%;
      }
      &:after {
        content: "";
        border-bottom: 1px solid #434343;
        position: absolute;
        bottom: 0;
        left: 30%;
        right: 30%;
        @media print {
          display: none;
        }
      }
      .slide {
        display: none;
        overflow: hidden;
        position: relative;
        @media print {
          position: relative;
          display: block !important;
          opacity: 1 !important;
        }
        &.active {
          display: block;
        }
        .copy, 
        .imgs {
          width: 50%;
          float: left;
          box-sizing: border-box;
          @media print {
            float: none;
            width: 100%;
          }
          img {
            width: 100%;
            height: auto;
            @media print {
              display: none;
            }
          }
        }
        .imgs {
          img {
            display: none;
            &.active {
              display: block;
            }
          }
        }
        .copy {
          padding-left: 60px;
          padding-top: 28px;
          h3 {
            color: $red;
            text-transform: uppercase;
            @extend .BreveSansBlack;
            font-size: 2.14285714285714em;
            display: block;
            text-align: left;
            line-height: 1.1em;
            margin: 0.9em 0 0.6em;
            letter-spacing: 0.025em;
            @media print {
              color: #000;
              font-size: 1.5em;
            }
          }
        }
      }
    }
    @include bp($tablet) {
      min-height: auto;
      .slides:after {
        display: none;
      }
      .slides {
        padding-top: 30px;
        top: auto;
        width: 475px;
        margin: 0 auto;
      }
      .slides .slide {
        .imgs, 
        .copy {
          float: none;
          width: 100%;
          padding: 0;
          color: #8a8a8a;
          h3 {
            font-size: 2.14285714285714em;
            text-align: left;
            margin: 0.9em 0 0.6em;
          }
        }
        figure {
          margin: 0 auto 40px;
        }
      }
    }
    @include bp($mobile) {
      .slides {
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px 40px;
        min-height: 0;
      }
      .slides .slide {
        .imgs, 
        .copy {
          text-align: center;
          float: none;
          width: 100%;
          padding: 0;
          color: #8a8a8a;
          h3 {
            font-size: 1.42857142857143em;
            text-align: center;
            margin: 0.9em 0 0.6em;
          }
        }
        .imgs {
          width: 270px;
          margin: 0 auto 55px;
          img {
            width: 100%;
            height: auto;
            width: auto;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .focus {
    padding: 5% 0;
    overflow: hidden;
    background: #000;
    position: relative;
    z-index: 20;
    @media print {
      display: none;
    }
    h1 {
      text-align: center;
      font-size: 4.35714285714286em;
      line-height: 1.5em;
      @extend .BreveBlack;
      @media print {
        font-size: 2em;
      }
      em {
        display: inline-block;
        padding-right: 10px;
        @extend .BreveBlackItalic;
      }
      @include bp($mobile) {
        font-size: 2.14285714285714em;
        margin-bottom: 1em;
      }
    }
    ul {
      width: 90%;
      margin: 0 auto;
      list-style: none;
      overflow: hidden;
      @include bp($mobile) {
        min-height: 200px;
      }
      @media print {
        width: 100%;
      }
      li {
        width: calc(100% / 3);
        border-right: 1px solid #434343;
        display: block;
        float: left;
        margin: 4% -1px 4% 0;
        box-sizing: border-box;
        padding: 0 3%;
        height: 100px;
        overflow: hidden;
        @include bp($tablet) {
          height: auto;
        }
        @media print {
          float: none;
          width: 100%;
          margin: 0;
          height: auto;
          opacity: 1 !important;
          border: 1px solid green;
        }
        figure {
          overflow: visible;
          height: 100%;
          position: relative;
          span {
            display: block;
            float: left;
            width: 38%;
            @media print {
              display: none;
            }
          }
          img {
            float: right;
            display: block;
          }
        }
        figcaption {
          @extend %vertical;
          font-size: 1.5em;
          line-height: 1.4em;
          padding-left: calc(42%);
          @extend .BreveNewsSemibold;
          width: 100%;
          box-sizing: border-box;
          position: absolute !important;
          @include bp($tablet) {
            top: auto;
            transform: translateY(0);
            padding: 0;
            position: relative;
          }
          @include bp($mobile) {
            position: relative !important;
          }
          @media print {
            top: 0;
            transform: translateY(0);
            padding: 0;
            position: relative !important;
          }
        }
        .more {
          margin-top: 0.9em;
          color: #fff;
          display: block;
          text-transform: uppercase;
          font-size: 0.6em;
          width: 100%;
          @extend .coupbold;
          font-weight: bold;
          letter-spacing: 0.02em;
          position: relative;
          height: 20px;
          line-height: 20px;
          transition-duration: $timing;
          &:before,
          &:after {
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            left: 0;
            top: 0;
          }
          &:before {
            height: 0px;
            top: 10px;
            border-bottom: 1px solid $red;
            position: relative;
            display: inline-block;
            margin-right: 14px;
            vertical-align: top;
          }
          &:after {
            width: 0px;
            left: 10px;
            border-right: 1px solid $red;
          }
          @include bp($mobile) {
            width: auto;
            margin-right: auto;
            margin-left: auto;
            text-align: left;
            display: inline-block;
            box-sizing: border-box;
            &:before {
              left: 0;
            }
          }
        }
        img {
          float: left;
          margin: 0 20px 0 0;
          display: block;
        }
        a:hover {
          color: $red;
          .more {
            color: $red;
          }
        }
      }
      li:nth-child(odd) {
        a {
          color: $green;
          .more:before, 
          .more:after {
            border-color: $green;
          }
          &:hover {
            .more {
              color: $green;
            }
          }
        }
      }
      li:nth-child(3n+3) {
        border-right: none;
      }
      @include bp($tablet) {
        &.js-slide-wrap {
          min-height: 200px;
        }
        li {
          border: none;
          figure {
            max-width: 400px;
            margin: 0 auto;
            text-align: center;
            & > span {
              min-height: 94px;
            }
            figcaption {
              padding-left: 150px;
              //@extend %vertical;
              //position: relative;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
            }
            span.more {
              display: inline-block;
              width: auto !important;
            }
            img {
              max-width: 120px;
              margin-right: 30px;
            }
          }
        }
      }
      @include bp($mobile) {
        width: 100%;
        margin: 0;
        li {
          width: 100%;
          float: none;
          margin: 0;
          border: none;
          figure {
            img {
              width: auto;
              display: block;
              float: none;
              margin: 0 auto;
              max-width: 100%;
            }
            span {
              float: none;
              margin: 0 auto;
            }
            figcaption {
              position: relative;
              padding: 0;
              top: 0;
              transform: translateY(0);
              text-align: center;
              
            }
          }
        }
      }
    }
    //@include bp($tablet) {
    @media screen and (min-width: 768px) and (max-width: 959px) {
      &.js-mobile-ss {
        .dots {
          display: none;
        }
        ul {
          li {
            position: relative !important;
            width: calc(100% / 3);
            display: block;
            float: left;
            margin: 4% -1px 4% 0;
            box-sizing: border-box;
            padding: 0 3%;
            height: auto;
            overflow: hidden;
            &:nth-of-type(3n + 1) {
              clear: left;
            }
            figure {
              display: block;
              position: relative;
              span {
                width: 100%;
                float: none;
                img {
                  float: none;
                  margin: auto;
                }
              }
              figcaption {
                display: block;
                padding-left: 0;
                top: auto;
                position: relative !important;
                transform: translateY(0);
              }
            }
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 15%;
        right: 15%;
        border-top: 1px solid #424242;
      }
      .dots {
        a {
          width: 14px;
          height: 14px;
          margin: 0 13px;
        }
      }
    }
    @include bp($mobile) {
      padding-top: 30px;
      .dots {
        padding-top: 0;
        padding-bottom: 27px;
        a {
          width: 12px;
          height: 12px;
          margin: 0 8px;
        }
      }
      /*
      &.js-mobile-ss {
        .dots {
          display: block;
        }
        ul {
          li {
            position: absolute !important;
            width: 100%;
            display: block;
            float: none;
            margin: 0;
            box-sizing: border-box;
            padding: 0 3%;
            height: auto;
            overflow: hidden;
            &:nth-of-type(3n + 1) {
              clear: left;
            }
            figure {
              display: block;
              position: relative;
              span {
                width: 100%;
                float: none;
                img {
                  float: none;
                  margin: auto;
                }
              }
              figcaption {
                display: block;
                padding-left: 0;
                top: auto;
                position: relative !important;
                transform: translateY(0);
              }
            }
          }
        }
      }
      */
    }
  }
}
.single-expertise {
  .top {
    overflow: hidden;
    position: relative;
    min-height: 600px;
    @include bp($tablet) {
      min-height: 0;
    }
    @media print {
      min-height: 0;
    }
    article {
      width: 50%;
      box-sizing: border-box;
      padding: 5% 7%;
      @include bp($mobile) {
        padding: 5% 20px;
      }
      @media print {
        width: 100%;
        padding: 0;
      }
      .entry {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        @media print {
          max-width: 100%;
        }
      }
      figure {
        width: 100%;
        margin-bottom: 20px;
        img, 
        figcaption {
          display: inline-block;
          vertical-align: middle;
        }
        img {
          margin: 0 5px 0 0;
        }
        figcaption {
          text-transform: uppercase;
          font-size: 0.92857142857143em;
          letter-spacing: 0.13em;
          @extend .BreveSansBold;
        }
      }
    }
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      background: transparent none no-repeat 50% 50%;
      background-size: cover;
      @media print {
        display: none;
      }
    }
    @include bp($tablet) {
      padding-top: 610px;
      article {
        width: 100%;
        float: none;
      }
      .bg {
        width: 100%;
        position: absolute;
        height: 610px;
        top: 0;
      }
    }
    @include bp($mobile) {
      padding-top: 0;
      article {
        width: 100%;
        float: none;
      }
      .bg {
        width: 100%;
        position: relative;
        height: 250px;
      }
    }
  }
  #other-expertise {
    width: 90%;
    margin: 0 auto 0;
    padding: 43px 24px 0 0;
    box-sizing: border-box;
    clear: both;
    position: relative;
    max-width: 1377px;
    border-top: 1px solid #434343;
    @media print {
      display: none;
    }
    .tabs-nav {
      height: 100px;
      font-size: 0.1px; /* IE 9 & 10 don't like font-size: 0; */
      width: 100%;
      text-align: justify;
      box-sizing: border-box;
      &:after {
        content: '';
        width: 100%; /* Ensures there are at least 2 lines of text, so justification works */
        display: inline-block;
      }
      a {
        text-align: center;
        display: inline-block;
        padding: 0 10px;
        line-height: 1.2em;
        box-sizing: border-box;
        vertical-align: top;
        position: relative;
        color: $green;
        font-size: 14px;
        &:hover {
          figure img {
            opacity: 0;
          }
          figure figcaption {
            opacity: 1;
          }
        }
        &.current, 
        &.current:hover {
          cursor: default;
          figure img {
            opacity: 0.4;
          }
          figure figcaption {
            opacity: 0;
          }
        }
        figure {
          position: relative;
          height: 82px;
          display: block;
          img {
            height: 100%;
            display: block;
            width: auto;
            margin: 0 auto;
            transition-duration: $timing;
          }
          figcaption {
            transition-duration: $timing;
            opacity: 0;
            @extend %vertical;
            @extend .BreveNewsSemibold;
            font-size: 1.42857142857143em;
            line-height: 1.1em;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            width: 100%;
            z-index: 10;
          }
        }
      }
      a:nth-child(even) {
        color: $red;
      }
      @include bp($mobile) {
        min-height: 160px;
        a {
          display: block;
          width: 100%;
          opacity: 0;
          &.current, 
          &.current:hover {
            cursor: pointer;
            opacity: 0;
            figure img {
              opacity: 1;
            }
            figure figcaption {
              opacity: 1;
            }
          }
          &.active {
            opacity: 1;
          }
          &.current {
            figure img {
              opacity: 0.4;
            }
          }
          figure {
            figcaption {
              position: relative;
              opacity: 1;
              top: auto;
              margin-top: 20px;
            }
          }
        }
      }
    }
    &.js-mobile-ss {
      @include bp($tablet) {
        .js-slide-wrap {
          min-height: 0;
        }
        .dots {
          display: none;
        }
        .tabs-nav a {
          min-width: 0;
          max-width: 90px;
          width: auto;
          padding: 0;
          display: inline-block;
          opacity: 1;
          position: relative !important;
          &.active {
            position: relative !important;
          }
        }
        .tabs-nav figure {
          figcaption {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            width: 100%;
            z-index: 10;
            margin: auto;
          }
        }
      }
      @include bp($mobile) {
        .js-slide-wrap {
          min-height: 160px;
        }
        .dots {
          display: block;
        }
        .tabs-nav a {
          max-width: 100%;
          width: 100%;
          display: block;
          opacity: 0;
          position: absolute !important;
          &.active {
            position: absolute !important;
            opacity: 1;
          }
        }
        .tabs-nav figure {
          figcaption {
            opacity: 1;
            position: relative;
            margin: auto;
            transform: translateY(0);
            top: 0;
          }
        }
      }
    }
  }
  .related {
    background: #000;
    overflow: hidden;
    padding: 6% 0 43px;
    color: #fff;
    @media print {
      display: none;
    }
    .project-list, 
    .thoughts {
      float: left;
      box-sizing: border-box;
      margin-bottom: 70px;
      h3 {
        text-transform: uppercase;
        @extend .BreveSansBlack;
        font-size: 2.14285714285714em;
        letter-spacing: 0.024em;
        line-height: 1em;
        margin: 0.4em 0 1em;
      }
    }
    .project-list {
      width: 67%;
      border-right: 1px solid #434343;
      margin-right: -1px;
      padding: 0 4%;
      h3:after {
        content: "*Red denotes a case study";
        color: $red;
        text-transform: uppercase;
        display: block;
        font-size: 0.4em;
        @extend .BreveSansMedium;
      }
      ul {
        list-style: none;
        width: 50%;
        float: left;
        margin: 0;
        padding: 0;
        a:hover {
          color: #fff;
        }
        li {
          margin-bottom: 1.82em;
          box-sizing: border-box;
          padding-right: 15%;
          h2 {
            color: #b0b0b0;
            font-size: 1.14285714285714em;
            line-height: 1.4375em;
            @extend .BreveNewsMedium;
          }
          p {
            text-transform: uppercase;
            font-size: 0.85714285714286em;
            @extend .BreveSansMedium;
            letter-spacing: 0.06em;
          }
        }
      }
    }
    .thoughts {
      padding: 0 4%;
      width: 33%;
      border-left: 1px solid #434343;
      .js-slide-wrap {
        overflow: hidden;
      }
      .more {
        font-size: 0.92857142857143em;
        letter-spacing: 0.05em;
        @extend .BreveSansBlack;
        display: inline-block;
        text-transform: uppercase;
        color: #fff;
        display: none;
        @include bp($mobile) {
          display: none;
        }
        &:hover {
          color: $green;
        }
      }
      article {
        padding: 0 0 2.75em;
        position: relative;
        clear: both;
        overflow: hidden;
        a:hover {
          color: $green;
          img {
            opacity: 0.8;
          }
        }
        p {
          color: #b0b0b0;
          font-size: 0.92857142857143em;
          line-height: 1.46153846153846em;
        }
        img {
          display: block;
          float: left;
          margin-right: 20px;
          width: 145px;
          height: auto;
          transition-duration: $timing;
        }
        h2 {
          color: #fff;
          font-size: 1.28571428571429em;
          line-height: 1.38888888888889em;
          @extend .BreveBlack;
        }
        .meta {
          text-transform: uppercase;
          @extend .BreveSansSemiBold;
          letter-spacing: 0.14em;
          display: block;
          margin-bottom: 0.2em;
        }
      }
    }
    @include bp($tablet) {
      padding-top: 40px;
      .project-list,
      .thoughts {
        width: 100%;
        float: none;
        clear: both;
        border: none;
        padding-left: 20px;
        padding-right: 20px;
      }
      .thoughts {
        text-align: left;
        position: relative;
        padding: 40px 40px 20px;
        .dots {
          display: none;
        }
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 20px;
          right: 20px;
        }
        .more {
          display: none;
        }
        article {
          text-align: left;
          padding: 0 20px 40px 0;
          box-sizing: border-box;
          width: 50%;
          float: left;
          opacity: 1;
          position: relative;
          display: block;
          clear: none;
          &:nth-child(odd) {
            clear: left;
          }
          img {
            display: block;
            float: left;
            margin: 5px 25px 0 0;
          }
          &.js-slide {
            opacity: 1;
            position: relative !important;
          }
        }
      }
      .project-list {
        padding: 20px 40px;
      }
    }
    @include bp($mobile) {
      padding-top: 40px;
      .project-list,
      .thoughts {
        width: 100%;
        float: none;
        border: none;
        padding-left: 20px;
        padding-right: 20px;
      }
      .thoughts {
        text-align: center;
        position: relative;
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 20px;
          right: 20px;
          border-top: 1px solid #434343;
        }
        .dots {
          display: block;
          a {
            margin: 0 10px;
          }
        }
        article {
          text-align: left;
          padding: 0 30px 40px;
          box-sizing: border-box;
          width: 100%;
          img {
            display: block;
            float: none;
            margin: 0 auto 15px;
          }
          &.js-slide {
            opacity: 0;
            position: absolute !important;
          }
          &.active {
            position: relative !important;
            opacity: 1;
          }
        }
      }
      .project-list {
        ul {
          width: 100%;
          float: none;
          li {
            width: 100%;
            float: none;
          }
        }
      }
    }
  }
}

.expertise__callout--wrap {
  position: relative;
  padding: 8.75% 0 0;
  
  @include bp($tablet) {    
    &:before, 
    &:after {
      content: "";
      border-bottom: 1px solid #434343;
      position: absolute;
      top: 0;
      left: 15%;
      right: 15%;
      @media print {
        display: none;
      }
    }
    
    &:after {
      bottom: 0px;
    }
  }
}

.expertise__callout {
  position: relative;
  width: 90%;
  max-width: 970px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  padding: 0 0 8.75% 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media print {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
  
  &:after {
    content: "";
    border-bottom: 1px solid #434343;
    position: absolute;
    bottom: 0;
    left: 30%;
    right: 30%;
    @media print {
      display: none;
    }
  }

  
  @include bp($tablet) {
    padding-top: 30px;
    top: auto;
    max-width: 475px;
    margin: 0 auto;
    
    &:after {
      display: none;
    }
  }
}



.expertise__callout--image,
.expertise__callout--copy {
  width: 50%;
  box-sizing: border-box;
  
  @media print {
    float: none;
    width: 100%;
  }
  
  @include bp($tablet) {
    width: 100%;
  }
}

.expertise__callout--image {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  @include bp($mobile) {
    max-width: 270px;
    margin: 0 auto;
  }
}

.expertise__callout--copy {
  padding-left: 60px;
  
  h3 {
    color: $green;
    text-transform: uppercase;
    @extend .BreveSansBlack;
    font-size: 2.14285714285714em;
    display: block;
    text-align: left;
    line-height: 1.1em;
    margin: 0.9em 0 0.6em;
    letter-spacing: 0.025em;
    @media print {
      color: #000;
      font-size: 1.5em;
    }
  }
  
  .more {
    margin-top: 0.9em;
    color: #fff;
    display: block;
    text-transform: uppercase;
    font-size: 0.9em;
    width: 100%;
    @extend .coupbold;
    font-weight: bold;
    letter-spacing: 0.02em;
    position: relative;
    height: 20px;
    line-height: 20px;
    transition-duration: $timing;
    
    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      left: 0;
      top: 0;
    }
    &:before {
      height: 0px;
      top: 10px;
      border-bottom: 1px solid $green;
      position: relative;
      display: inline-block;
      margin-right: 14px;
      vertical-align: top;
    }
    &:after {
      width: 0px;
      left: 10px;
      border-right: 1px solid $green;
    }
    
    @include bp($mobile) {
      width: auto;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      display: inline-block;
      box-sizing: border-box;
      &:before {
        left: 0;
      }
    }
  }
  
  @include bp($tablet) {
    color: #8a8a8a;
    padding: 0;
    
    h3 {
      font-size: 2.14285714285714em;
      margin: 0.9em 0 0.6em;
    }
  }
  
  @include bp($mobile) {
    padding: 0;
    text-align: center;
    
    h3 {
      font-size: 1.42857142857143em;
      text-align: center;
      margin: 0.9em 0 0.6em;
    }
  }
}


