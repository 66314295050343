/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	vertical-align: top;
}

.fancybox-wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8020;
}

.fancybox-skin {
	position: relative;
	color: #444;
	text-shadow: none;
}

.fancybox-opened {
	z-index: 8030;
}

.fancybox-opened .fancybox-skin {
}

.fancybox-outer, .fancybox-inner {
	position: relative;
}

.fancybox-inner {
	overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
	-webkit-overflow-scrolling: touch;
}

.fancybox-error {
	color: #444;
	font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
	margin: 0;
	padding: 15px;
	white-space: nowrap;
}

.fancybox-image {
	display: block;
	width: 100%;
	height: 100%;
}

.fancybox-inner {
	display: block;
	width: 100%;
	position: relative;
	//padding-bottom: 56.25%; /* 16:9 */
	//padding-top: 25px;
  iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	bottom: 0;
    height: 100% !important;
  }
}

.fancybox-type-inline, 
.fancybox-type-html,
.fancybox-type-ajax {
	width: 85% !important;
	max-width: 1200px;
	font-size: 1.6em;
	//margin-bottom: 20px;

	.fancybox-inner {
		height: auto !important;
		width: 100% !important;
	}
}

.fancybox-type-iframe {
	width: 60% !important;
	max-width: 100%;
	font-size: 1.6em;

	.fancybox-inner {
		height: auto !important;
		width: 100% !important;
		padding-bottom: 56.25%;
	}

	@include bp($tablet) {
		width: 85% !important;
	}

	@include bp(400px) {
		width: 100% !important;
		left: 0 !important;
	}
}

.fancybox-image {
	max-width: 100%;
	max-height: 100%;
}

.fancybox-gallery {
	img {
		display: block;
	}

	figcaption {
		background: #f7f7f7;
		font-style: italic;
		font-weight: 600;
		color: #80807d;
		padding: 15px 36px;
	}
}

#fancybox-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -20px;
	margin-left: -40px;
	background-position: 0 -108px;
	opacity: 0.8;
	cursor: pointer;
	z-index: 8060;
}

#fancybox-loading div {
	height: 40px;
	width: 80px;
	background: url(../images/ellipsis.gif) no-repeat center center;
	background-color: #ada9a9;
	opacity: .3;
}

.fancybox-close {
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 8040;
  	text-align: center;
  	color: $white;
  	background: $red;
  	transition: .2s;

  	@include bp($mobile) {
  		//top: -40px;
  	}

  	&:hover {
    	color: $red;
  	}

  	&:before, &:after {
  		content: "";
  		position: absolute;
  		width: 20px;
  		height: 1px;
  		background: $white;
  		top: calc(50% - 1px);
  		left: calc(50% - 10px);
  	}

  	&:before {
  		transform: rotate(45deg);
  	}

  	&:after {
		transform: rotate(-45deg);
  	}

  	svg {
    	fill: currentColor;
  	}
}

.fancybox-nav {
	position: absolute;
	top: 0;
	width: 40%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	//background: transparent url('../images/blank.gif'); /* helps IE */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	z-index: 8040;
  opacity: 1;
  transition-duration: .2s;
  transition-property: opacity;
  &:hover {
    opacity: 0.75;
  }
}

.fancybox-prev {
	left: -20px;
}

.fancybox-next {
	right: -20px;
}

.fancybox-nav span {
	position: absolute;
	top: 50%;
	width: 36px;
	height: 40px;
	margin-top: -18px;
	cursor: pointer;
	z-index: 8040;
	visibility: visible;
	color: $white;
	background: #111;

	svg {
		fill: currentColor;
		width: 36px;
		height: 20px;
	}
}

.fancybox-prev span {
	left: 0;
	text-align: left;
}

.fancybox-next span {
	right: 0;
	text-align: right;
}

.fancybox-nav:hover span {
	visibility: visible;
}

.fancybox-tmp {
	position: absolute;
	top: -99999px;
	left: -99999px;
	visibility: hidden;
	max-width: 99999px;
	max-height: 99999px;
	overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;

    @include bp($mobile) {
    	height: auto;
	}
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
	z-index: 8010;
	background: rgba(#282828, .85);
}

.fancybox-overlay-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

.fancybox-lock .fancybox-overlay {
	overflow: auto;
	overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
	visibility: hidden;
	font-size: 13px;
  	line-height: 21.17px;
	position: relative;
	text-shadow: none;
	z-index: 8050;
}

.fancybox-opened .fancybox-title {
	visibility: visible;
}

.fancybox-title-float-wrap {
	position: absolute;
	//bottom: 0;
	top: 100%;
	left: 0;
	right: 0;
	//right: 50%;
	//margin-bottom: -35px;
	z-index: 8050;
	background: rgba(0, 0, 0, 0.8);
	//text-align: center;

	@include bp($mobile, true) {
		bottom: 0;
		top: auto;
	}
}

.fancybox-title-float-wrap .child {
	display: inline-block;
	padding: 10px 20px 12px;
	background: transparent; /* Fallback for web browsers that doesn't support RGBa */
	//background: rgba(0, 0, 0, 0.8);
	color: #FFF;
	//font-weight: bold;
	line-height: 24px;
	box-sizing: border-box;
	width: 100%;
	//white-space: nowrap;
}

.fancybox-title-outside-wrap {
	position: relative;
	//margin-top: 10px;
	color: #fff;
}

.fancybox-title-inside-wrap {
	padding-top: 10px;
}

.fancybox-title-over-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	padding: 10px;
	background: #000;
	background: rgba(0, 0, 0, .8);
}
