
::selection {
 background: $green;
 color: #fff;
}


* { 
  margin: 0px;
  padding: 0px;
}
a {
  img { 
    border: none; 
    outline: none; 
    transition-duration: .3s; 
    transition-property: color,border,background-color,opacity; 
  }
}
a { 
  color: $red; 
  text-decoration: none;
  transition-duration: $timing;
  transition-property: color,background-color,opacity;
  &:hover { 
    color: #000; 
  }
  @media print {
    color: #000;
  }
}
input, 
button, 
textarea {
  -webkit-appearance: none;
      border-radius: 0;
}
header, 
section, 
article, 
hgroup, 
aside, 
footer { 
  display: block; 
}
html {
   -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}
body { 
  background: #fff; 
  font-size: 14px; 
  @extend .BreveSansBook;
  line-height: 1.5em; 
  -webkit-font-smoothing: antialiased;
}

html, body {
  height: 100%;
}
.page-wrap {
  padding-top: 84px;
  min-height: 100%;
  margin-bottom: -190px; 
  box-sizing: border-box;
  overflow: hidden;
  @include bp($tablet) {
    margin-bottom: 0;
  }
  @include bp($mobile) {
    padding-top: 0;
  }
  @media print {
    margin: 0;
    padding: 0;
    min-height: 0;
  }
}
.page-wrap:after {
  content: "";
  display: block;
  @media print {
    display: none;
  }
}
footer[role="main"], .page-wrap:after {
  height: 190px; 
  @include bp($tablet) {
    height: auto;
  }
  @media print {
    height: auto;
  }
}